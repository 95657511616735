import React, { useState } from "react";
import {
  Button,
  Box,
  makeStyles,
  Typography,
  Divider,
  useTheme,
} from "@material-ui/core";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import FilterListIcon from "@material-ui/icons/FilterList";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import Header from "../../core/Header";
import Content from "../../core/layout/Content";
import { Popup, PopupCard } from "../../core/Popup";
import { useMenu } from "../../../context/menu-context";
import CircleButton from "../../core/CircleButton";
import HeaderActions from "../../core/HeaderActions";
import NotificationCardSkeleton from "../../core/skeleton/NotificationCardSkeleton";
import InterventionCardSkeleton from "../../core/skeleton/InterventionCardSkeleton";
import WizardSkeleton from "../../core/skeleton/WizardSkeleton";

import DataExample from "./DataExample";
import WizardExample from "./WizardExample";
import UploadExample from "./UploadExample";
import {
  InterventionCardExample1,
  InterventionCardExample2,
  InterventionCardExample3,
  InterventionCardExample4,
  InterventionCardExample5,
} from "./InterventionCardExample";
import {
  NotificationCardExample1,
  NotificationCardExample2,
  NotificationCardExample3,
} from "./NotificationCardExample";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
  },
  container: {
    padding: theme.spacing(3, 2, 3, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
  contentBox: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& button": {
      margin: theme.spacing(0, 2, 0, 2),
    },
  },
  section: {
    color: theme.palette.text.primary,
  },
  marginTop: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  marginSide: {
    margin: theme.spacing(0, 2, 0, 2),
  },
  margin: {
    margin: theme.spacing(2),
  },
}));

const AlertComponent = () => {
  const alert = useAlert();
  const classes = useStyle();
  const theme = useTheme();
  const msg =
    "Attention, vous risquez de perde votre progression, vérifiez votre connexion avant d'actualiser votre navigateur internet.";
  const title = "Vous êtes déconnecté";

  return (
    <Box className={classes.contentBox}>
      <Button
        variant="contained"
        style={{
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
        }}
        onClick={() => {
          alert.show(msg, {
            title: title,
            close: () => {
              console.log("close");
              alert.remove(alert);
            },
            type: "warning",
          });
        }}
      >
        Warning
      </Button>
      <Button
        variant="contained"
        style={{
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        }}
        onClick={() => {
          alert.show(msg, {
            title: title,
            close: () => {
              console.log("close");
              alert.remove(alert);
            },
            type: "error",
          });
        }}
      >
        Error
      </Button>
      <Button
        variant="contained"
        style={{
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
        }}
        onClick={() => {
          alert.show(msg, {
            title: title,
            close: () => {
              console.log("close");
              alert.remove(alert);
            },
            type: "success",
          });
        }}
      >
        Success
      </Button>
      <Button
        variant="contained"
        style={{
          backgroundColor: theme.palette.info.main,
          color: theme.palette.info.contrastText,
        }}
        onClick={() => {
          alert.show(msg, {
            title: title,
            close: () => {
              console.log("close");
              alert.remove(alert);
            },
            type: "info",
          });
        }}
      >
        Info
      </Button>
      <Button
        variant="contained"
        style={{
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
        }}
        onClick={() => {
          alert.show(msg, {
            title: title,
            close: () => {
              console.log("close");
              alert.remove(alert);
            },
            type: "wifioff",
          });
        }}
      >
        Wifi Off
      </Button>
    </Box>
  );
};

const ComponentSection = ({ title, withoutDivider, children }) => {
  const classes = useStyle();
  return (
    <Box className={`${classes.marginTop} ${classes.section}`}>
      {!withoutDivider && <Divider />}
      <Typography className={classes.marginTop} variant="h4">
        {title}
      </Typography>
      {children}
    </Box>
  );
};

const PopupComponent = () => {
  const classes = useStyle();
  const [state, setstate] = useState(false);

  return (
    <Box className={classes.contentBox}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setstate(!state);
        }}
      >
        Afficher la popup
      </Button>
      {state === true && (
        <>
          <Popup>
            <PopupCard
              title="Pop-up"
              description="Description d'un pop-up avec un texte"
              action={() => console.log("test")}
              actionLabel="Action"
              actionIcon={<ArrowForwardIcon />}
            >
              Contenu d'une pop-up <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              bibendum tristique urna ac molestie. Sed at lorem ipsum. Donec
              tincidunt aliquet velit. Quisque euismod euismod odio. Vivamus
              feugiat sodales orci, sed hendrerit sem venenatis quis. Aliquam
              rhoncus vestibulum sapien sit amet varius. Praesent facilisis a
              sem quis posuere. Mauris aliquet ante metus, sed euismod leo
              scelerisque et. Ut eu sapien sem. Cras ut erat eu ex maximus
              efficitur in in neque. Donec non neque ut est semper pellentesque
              non vel nibh. Vivamus aliquam ante sapien, ut gravida lacus
              condimentum ut. Etiam mollis interdum semper. Praesent non mi
              scelerisque, condimentum ipsum eget, bibendum erat. Proin in
              laoreet lacus, vitae pulvinar augue. Nullam pellentesque
              condimentum magna eu hendrerit.
            </PopupCard>
            <PopupCard
              title="Autre section"
              description="Description d'un pop-up avec un texte"
              action={() => console.log("test 2")}
              actionLabel="Ajouter"
              actionIcon={<AddCircleOutline />}
            />
          </Popup>
        </>
      )}
    </Box>
  );
};

const OldListComponents = () => {
  const { t } = useTranslation();
  const menu = useMenu();
  const classes = useStyle();

  return (
    <>
      <Header
        focus={false}
        title={t("Components")}
        titleIcon={<DeveloperModeIcon />}
        titleDesc={t("ComponentsList")}
      >
        {menu.onSmartphone ? (
          <HeaderActions>
            <CircleButton color="primary">
              <FilterListIcon />
            </CircleButton>
            <CircleButton color="primary" variant="contained">
              <AddCircleOutline />
            </CircleButton>
          </HeaderActions>
        ) : (
          <HeaderActions>
            <Button color="primary" endIcon={<FilterListIcon />}>
              {t("SortOrFilter")}
            </Button>
            <Button
              style={{ margin: "0px 16px" }}
              color="primary"
              variant="contained"
              endIcon={<AddCircleOutline />}
            >
              {t("NewIntervention")}
            </Button>
          </HeaderActions>
        )}
      </Header>

      <Content>
        <Box className={classes.root}>
          <ComponentSection title="Données de l'application">
            <DataExample />
          </ComponentSection>

          <ComponentSection title="Alerte">
            <AlertComponent />
          </ComponentSection>

          <ComponentSection title="Popup">
            <PopupComponent />
          </ComponentSection>

          <ComponentSection title="Carte d'intervention">
            <Box display="flex" flexWrap="wrap">
              <Box m={2}>
                <InterventionCardExample1 />
              </Box>
              <Box m={2}>
                <InterventionCardExample2 />
              </Box>
              <Box m={2}>
                <InterventionCardExample3 />
              </Box>
              <Box m={2}>
                <InterventionCardSkeleton />
              </Box>
              <Box m={2}>
                <InterventionCardExample4 />
              </Box>
              <Box m={2}>
                <InterventionCardExample5 />
              </Box>
            </Box>
          </ComponentSection>

          <ComponentSection title="Carte de notification">
            <Box display="flex" flexWrap="wrap">
              <Box m={2}>
                <NotificationCardExample1 />
              </Box>
              <Box m={2}>
                <NotificationCardExample2 />
              </Box>
              <Box m={2}>
                <NotificationCardExample3 />
              </Box>
              <Box m={2}>
                <NotificationCardSkeleton />
              </Box>
            </Box>
          </ComponentSection>

          <ComponentSection title="Wizard">
            <WizardExample></WizardExample>
            <Box className={classes.marginTop}>
              <WizardSkeleton></WizardSkeleton>
            </Box>
          </ComponentSection>

          <ComponentSection title="Bouton de téléversement">
            <UploadExample />
          </ComponentSection>
        </Box>
      </Content>
    </>
  );
};

export default OldListComponents;
