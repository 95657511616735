import React from "react";
import InterventionCard, {
  InterventionCardContentLine,
} from "../../core/InterventionCard";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import InfoIcon from "@material-ui/icons/Info";
import CheckIcon from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import GetAppIcon from "@material-ui/icons/GetApp";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import SaveIcon from "@material-ui/icons/Save";
import {
  Button,
  Box,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(2, 2, 2, 2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

const InterventionCardExample1 = () => {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <InterventionCard
      interRef="#REF_INTERVENTION"
      icon={<SaveIcon />}
      footer="Dernière modification il y a 5 minutes"
    >
      <InterventionCardContentLine left="Votre référence" right="#REF_CLIENT" />
      <InterventionCardContentLine left="Responsable" right="NOM Prénom" />

      <Box display="flex" flexDirection="column" className={classes.marginTop}>
        <Button
          variant="contained"
          startIcon={<OpenInNewIcon />}
          className={classes.marginBottom}
          style={{
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
          }}
        >
          Finaliser ma demande
        </Button>
        <Typography variant="body2" color="textSecondary" align="center">
          Votre colis à été pris en charge par notre transporteur, aucune action
          n’est requise
        </Typography>
      </Box>
    </InterventionCard>
  );
};

const InterventionCardExample2 = () => {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <InterventionCard
      interRef="#REF_INTERVENTION"
      icon={<InfoIcon />}
      footer="Dernière modification il y a 15 minutes"
    >
      <Box display="flex" flexDirection="column" className={classes.marginTop}>
        <Button
          variant="contained"
          startIcon={<OpenInNewIcon />}
          className={classes.marginBottom}
          style={{
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
          }}
        >
          En savoir plus
        </Button>
        <Typography variant="body2" color="textSecondary" align="center">
          Votre demande d'intervention a été refusée
        </Typography>
      </Box>
    </InterventionCard>
  );
};

const InterventionCardExample3 = () => {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <InterventionCard
      interRef="#REF_INTERVENTION"
      icon={<SettingsIcon />}
      footer="Dernière modification il y a 15 minutes"
    >
      <Box display="flex" flexDirection="column" className={classes.marginTop}>
        <Button
          startIcon={<CheckIcon />}
          className={classes.marginBottom}
          style={{
            color: theme.palette.text.disabled,
          }}
        >
          En réparation
        </Button>
        <Typography variant="body2" color="textSecondary" align="center">
          En cours de préparation dans notre atelier
        </Typography>
      </Box>
    </InterventionCard>
  );
};

const InterventionCardExample4 = () => {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <InterventionCard
      interRef="#REF_INTERVENTION"
      icon={<NotificationsActiveIcon />}
      footer="Dernière modification il y a 55 minutes"
    >
      <InterventionCardContentLine left="Votre référence" right="#REF_CLIENT" />

      <Box display="flex" flexDirection="column" className={classes.marginTop}>
        <Button
          variant="contained"
          startIcon={<GetAppIcon />}
          className={classes.marginBottom}
          style={{
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
          }}
        >
          Préparer mon colis
        </Button>
        <Typography variant="body2" color="textSecondary" align="center">
          Date de retrait : Aujourd’hui à 18h
        </Typography>
      </Box>
    </InterventionCard>
  );
};

const InterventionCardExample5 = () => {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <InterventionCard
      interRef="#REF_INTERVENTION"
      icon={<CheckIcon />}
      footer="Dernière modification il y a 1 heure"
    >
      <InterventionCardContentLine left="Votre référence" right="#REF_CLIENT" />
      <InterventionCardContentLine
        left="Responsable"
        right="FERNANDES ALVES Fabien"
      />

      <Box display="flex" flexDirection="column" className={classes.marginTop}>
        <Button
          variant="contained"
          startIcon={<StarHalfIcon />}
          className={classes.marginBottom}
          style={{
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
          }}
        >
          Noter cette intervention
        </Button>
        <Typography variant="body2" color="textSecondary" align="center">
          Cette intervention est terminée 🎉
        </Typography>
      </Box>
    </InterventionCard>
  );
};

export {
  InterventionCardExample1,
  InterventionCardExample2,
  InterventionCardExample3,
  InterventionCardExample4,
  InterventionCardExample5,
};
