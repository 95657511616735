import React, { useState, useEffect } from "react";

import { Checkbox, FormControlLabel, FormHelperText } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { emptyAddress } from "../../../utils/model";
import CotroliaSelect from "./CotroliaSelect";
import CotroliaFormControl from "./CotroliaFormControl";
import CotroliaAutocomplete from "./CotroliaAutocomplete";
import CotroliaTextField from "./CotroliaTextField";
import { MenuItem } from "@material-ui/core";
import { useConfiguration } from "../../../context/configuration-context";
import CotroliaInputLabel from "./CotroliaInputLabel";

const CotroliaAddress = ({
  label,
  updateAddress,
  required = false,
  defaultAddress = { ...emptyAddress },
  error,
  errorMsg,
  disabled = false
}) => {
  const { t } = useTranslation();
  const [addressNotFound, setAddressNotFound] = useState(false);
  const [address, setAddress] = useState(defaultAddress);

  useEffect(() => {
    if (disabled) {
      setAddressNotFound(false);
    }
  }, [disabled])

  /**
   * Update the component address
   *
   * @param {string} field    Key to modify
   * @param {string} value    Value to set
   */
  const updateLocalAddress = (field, value) => {
    
    let newAddress = null;

    if (typeof value === "object") {
      newAddress = value;
    } else {
      newAddress = { ...address };
      newAddress[field] = value;

      // Update full address field when we're updating not found address
      if (field !== "fullAddress") {
        if (
          newAddress.street !== "" &&
          newAddress.city !== "" &&
          newAddress.zipCode !== "" &&
          newAddress.countryCode !== ""
        ) {
          newAddress.fullAddress = `${newAddress.street} ${newAddress.zipCode} ${newAddress.city}, ${newAddress.countryCode}`;
        } else {
          newAddress.fullAddress = "";
        }
      }
    }

    setAddress(newAddress);
    updateAddress(newAddress) // Update parent address each change
  };

  const configuration = useConfiguration();

  const handleMethodChange = (e) => {
    const value = e.target.value;
    updateAddress("countryCode", "method", value);
  };
  /**
   * Get address suggestions from french gouv api
   *
   * @param {string} value    Address to search
   * @returns                 List of address
   */
  const getSuggestions = (value) => {
    if (value !== "" && value !== undefined && value[0] !== " ") {
      return new Promise((resolve, reject) => {
        fetch("https://api-adresse.data.gouv.fr/search/?q=" + value)
          .then((res) => res.json())
          .then((res) => {
            let suggestions = [];
            res.features.forEach((item) => {
              let entry = { ...emptyAddress };
              entry.street = item.properties.name;
              entry.city = item.properties.city;
              entry.zipCode = item.properties.postcode;
              entry.country = "France"; // Country is fix with France since it's the france gouv api
              entry.countryCode = "FR"
              entry.fullAddress = `${entry.street} ${entry.zipCode} ${entry.city}, ${entry.country}`;
              suggestions.push(entry);
            });
            resolve(suggestions);
          })
          .catch((err) => reject(err));
      });
    } else {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
  };

  return (
    <>
      <CotroliaFormControl error={error}>
        {!addressNotFound ? (
          <>
            <CotroliaAutocomplete
              id="address"
              label={label}
              value={address}
              setValue={(newValue) => {
                updateLocalAddress("fullAddress", newValue);
              }}
              getSuggestions={getSuggestions}
              required={required}
              error={error}
              isSuggestionObject={true}
              labelKey="fullAddress"
              helperText={t("addressHelperText")}
              disabled={disabled}
            />
          </>
        ) : (
          // if address not found output form address + zipcode + city + coutnry
            <>
            <CotroliaFormControl>
              <CotroliaTextField
                required={required}
                id="address"
                label={t("ShippingAddress")}
                value={address.street || ""}
                onChange={(e) => {
                  updateLocalAddress("street", e.target.value);
                }}
                error={error && address.street === ""}
              />
            </CotroliaFormControl>
            <CotroliaFormControl>
              <CotroliaTextField
                required={required}
                id="zipcode"
                label={t("ZipCode")}
                value={address.zipCode || ""}
                onChange={(e) => {
                  updateLocalAddress("zipCode", e.target.value);
                }}
                error={error && address.zipCode === ""}
              />
            </CotroliaFormControl>
            <CotroliaFormControl>
              <CotroliaTextField
                required={required}
                id="city"
                label={t("City")}
                value={address.city || ""}
                onChange={(e) => {
                  updateLocalAddress("city", e.target.value);
                }}
                error={error && address.city === ""}
              />
            </CotroliaFormControl>
              <CotroliaFormControl variant="filled" required>
                <CotroliaInputLabel id="field-label">{t('CountryCode')}</CotroliaInputLabel>
                <CotroliaSelect
                  id="country"
                  labelId={t("CountryCode")}
                  value={address.countryCode}
                  onChange={(e) => {
                    updateLocalAddress("countryCode", e.target.value);
                  }}
                    error={error && address.countryCode === ""}
                >
                  {Object.keys(configuration.countries).map((entry) => (
                    <MenuItem key={configuration.countries[entry].code} value={configuration.countries[entry].code}>
                    {`${configuration.countries[entry].label} (${configuration.countries[entry].code})`}
                </MenuItem>
                  ))}
                 
                </CotroliaSelect>
                <FormHelperText>{ t('RestrictedShippingCountries')}</FormHelperText>
            </CotroliaFormControl>
          </>
        )}
        {!disabled && <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={addressNotFound}
              onChange={() => {
                const newValue = !addressNotFound;
                setAddressNotFound(newValue);
                updateLocalAddress("unknown", newValue);
              }}
            />
          }
          label={t("AddressNotFound")}
          variant="outlined"
        />}
        <FormHelperText variant="outlined">{errorMsg}</FormHelperText>
      </CotroliaFormControl>
    </>
  );
};

export default CotroliaAddress;
