import {
  Box,
  Button,
  FormControl,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CotroliaInputLabel from "./input/CotroliaInputLabel";
import CotroliaSelect from "./input/CotroliaSelect";
import FilterListIcon from "@material-ui/icons/FilterList";
import CotroliaTextField from "./input/CotroliaTextField";
import { useAuth } from "../../context/auth-context";
import AppConstant from "../../utils/appConstant";
import authService from "../../services/auth.service";

const useStyle = makeStyles((theme) => ({
  root: {
    marginBottom: "2em",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing(1)}px 0`,
    "& > .MuiFormControl-root": {
      flexGrow: 1,
      margin: theme.spacing(1),
      minWidth: 150,
    },
  },
  actionBtn: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    "& button": {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

const SortOrFilter = ({ applyFilters, variant = "ongoing" }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [searchManager, setSearchManager] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchRef, setSearchRef] = useState("");
  const [managers, setManagers] = useState(null);
  const { user } = useAuth();
  const [statusEntries, setStatusEntries] = useState([]);

  useEffect(() => {
    if (user && user.data) {
      authService
        .getUsers(user.data.thirdparty.id)
        .then((res) => {
          setManagers(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [user]);

  useEffect(() => {
    if (variant === "ongoing") {
      setStatusEntries([
        {
          value: AppConstant.interStatusRegistered,
          label: t("StatusDraft"),
        },
        {
          value: AppConstant.interStatusTransmited,
          label: t("StatusTransmited"),
        },
        {
          value: AppConstant.interStatusToSend,
          label: t("StatusToSend"),
        },
        {
          value: AppConstant.interStatusSended,
          label: t("StatusSended"),
        },
        {
          value: AppConstant.interStatusReceive,
          label: t("StatusReceive"),
        },
        {
          value: AppConstant.interStatusQuoteReceive,
          label: t("StatusQuoteReceive"),
        },
        {
          value: AppConstant.interStatusQuoteAccepted,
          label: t("StatusQuoteAccepted"),
        },
        {
          value: AppConstant.interStatusPaymentPending,
          label: t("StatusPaymentPending"),
        },
        {
          value: AppConstant.interStatusRepair,
          label: t("StatusRepair"),
        },
        {
          value: AppConstant.interStatusOnTheWay,
          label: t("StatusOnTheWay"),
        },
        {
          value: AppConstant.interStatusAlert,
          label: t("StatusAlert"),
        },
      ]);
    } else {
      setStatusEntries([
        {
          value: AppConstant.interStatusDeclined,
          label: t("StatusDeclined"),
        },
        {
          value: AppConstant.interStatusFinalized,
          label: t("StatusFinalized"),
        },
      ]);
    }
  }, [t, variant]);

  const handleResetClick = () => {
    setSearchManager("");
    setSearchStatus("");
    setSearchRef("");
    applyFilters(null);
  };

  const handleFilterClick = () => {
    let filters = {};

    if (searchStatus !== "") {
      filters.fk_statut = searchStatus;
    }

    if (searchManager !== "") {
      filters.fk_user_author = searchManager;
    }

    if (searchRef !== "") {
      filters.ref_customer = searchRef;
    }

    applyFilters(filters);
  };

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <FilterListIcon style={{ marginRight: 5 }} />
        <Typography variant="h6">{t("Filters")}</Typography>
      </Box>

      <Box className={classes.filterContainer}>
        {/* Status */}
        <FormControl variant="filled">
          <CotroliaInputLabel>{t("FilterLabelStatus")}</CotroliaInputLabel>
          <CotroliaSelect
            value={searchStatus}
            onChange={(e) => {
              setSearchStatus(e.target.value);
            }}
          >
            <MenuItem value="">&nbsp;</MenuItem>
            {statusEntries.map((entry) => (
              <MenuItem key={entry.value} value={entry.value}>
                {entry.label}
              </MenuItem>
            ))}
          </CotroliaSelect>
        </FormControl>

        {/* Manager */}
        <FormControl variant="filled">
          <CotroliaInputLabel>{t("Manager")}</CotroliaInputLabel>
          <CotroliaSelect
            value={searchManager}
            onChange={(e) => {
              setSearchManager(e.target.value); // TODO : load manager from dolibarr
            }}
          >
            <MenuItem value="">&nbsp;</MenuItem>
            {managers &&
              managers.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.lastname + " " + item.firstname}
                </MenuItem>
              ))}
          </CotroliaSelect>
        </FormControl>

        {/* Client ref */}
        <FormControl>
          <CotroliaTextField
            label={t("YourRef")}
            value={searchRef}
            onChange={(e) => {
              setSearchRef(e.target.value);
            }}
          />
        </FormControl>
      </Box>
      <Box className={classes.actionBtn}>
        <Button variant="outlined" color="primary" onClick={handleResetClick}>
          {t("Reset")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleFilterClick}>
          {t("Filter")}
        </Button>
      </Box>
    </Box>
  );
};

export default SortOrFilter;
