import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
  Box,
  useTheme,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import AppConstant from "../../../utils/appConstant";
import MemoryIcon from "@material-ui/icons/Memory";

const useStyle = makeStyles((theme) => ({
  card: {
    width: AppConstant.notificationCardWidth,
    height: "100%",
    "& .MuiCardActions-root": {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      width: AppConstant.notificationCardWidthSm,
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(3),
  },
}));

const NotificationCardExample3 = () => {
  const theme = useTheme();
  const classes = useStyle();
  const header = "Interventions";
  const title = "Vous n’avez aucune demande d’intervention en cours";
  const desc = "";

  return (
    <Card
      className={classes.card}
      style={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }}
    >
      <CardContent>
        <Box className={classes.header}>
          <Typography variant="overline" style={{ flex: 1 }}>
            {header}
          </Typography>
          <MemoryIcon />
        </Box>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">{desc}</Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          style={{
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main,
          }}
        >
          Nouvelle intervention
        </Button>
      </CardActions>
    </Card>
  );
};

const NotificationCardExample2 = () => {
  const theme = useTheme();
  const classes = useStyle();
  const header = "Votre attention";
  const title = "Politique de confidentialité";
  const desc =
    "Nous sommes ravis de vous voir ici, découvrez la nouvelle interface de demande d’intervention";

  return (
    <Card
      className={classes.card}
      style={{ backgroundColor: theme.palette.warning.main }}
    >
      <CardContent>
        <Box className={classes.header}>
          <Typography variant="overline" style={{ flex: 1 }}>
            {header}
          </Typography>
          <InfoIcon />
        </Box>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">{desc}</Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          style={{
            backgroundColor: theme.palette.warning.contrastText,
            color: theme.palette.warning.main,
          }}
        >
          Accepter
        </Button>
        <Button
          style={{
            color: theme.palette.warning.contrastText,
          }}
        >
          Refuser
        </Button>
      </CardActions>
    </Card>
  );
};

const NotificationCardExample1 = () => {
  const classes = useStyle();
  const header = "Pour bien démarer";
  const title = "Bienvenue chez Repturn";
  const desc =
    "Nous sommes ravis de vous voir ici, découvrez la nouvelle interface de demande d’intervention";

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box className={classes.header}>
          <Typography variant="overline" style={{ flex: 1 }}>
            {header}
          </Typography>
          <InfoIcon />
        </Box>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">{desc}</Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="primary">
          Découvrir
        </Button>
        <Button color="primary">Ne plus afficher</Button>
      </CardActions>
    </Card>
  );
};

export {
  NotificationCardExample1,
  NotificationCardExample2,
  NotificationCardExample3,
};
