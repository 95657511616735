import {
  Box,
  makeStyles,
  Typography,
  useTheme,
  Hidden,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import ReportOutlinedIcon from "@material-ui/icons/ReportOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import React from "react";
import { useEffect, useState, useCallback } from "react";

const useStyle = makeStyles((theme) => ({
  corner: {
    maxWidth: "540px",
    margin: theme.spacing(3, 3, 0, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 1, 0, 1),
    },
  },
  container: {
    padding: theme.spacing(3, 2, 3, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 1, 0),
    },
    display: "flex",
    borderRadius: theme.spacing(0.5),
    boxShadow: "0px 5px 15px 0px rgba(115, 55, 0, 0,15)",
  },
  icon: {
    height: "50px",
    width: "50px",
    alignSelf: "center",
  },
  comments: {
    margin: theme.spacing(0, 3, 0, 3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 2, 0, 2),
    },
  },
  close: {
    height: "44px",
    width: "44px",
    padding: theme.spacing(1),
    pointerEvents: "all",
    cursor: "pointer",
    marginLeft: "auto",
  },
  timerLine: {
    display: "block",
    position: "relative",
    left: "0",
    bottom: "5px",
    height: "1px",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    borderBottom: "4px solid #faa19a",
    transition: "0.2s linear",
  },
}));

/**
 * Custom react alert template component
 * Options param should contain : {title: "string", type: "string"}
 * Options.type can be : "success", "error", "warning", "info" or "wifioff"
 *
 * @param {*} param0
 * @returns
 */
const Corner = ({ style, options, message, close }) => {
  // Load classes
  const classes = useStyle();
  const theme = useTheme();
  const [timer, setTimer] = useState(options.timeout);
  const [timerWidth, setTimerWidth] = useState(100);
  let type = "";

  if (options.type === "wifioff") {
    type = "warning";
  } else {
    type = options.type;
  }

  const calcTimerWidth = useCallback(() => {
    let newTimer = parseInt(timer) - 220;
    let percent = (newTimer / options.timeout) * 100;
    if (percent <= 0) {
      percent = 0;
    }
    return [newTimer, percent];
  }, [options.timeout, timer]);

  useEffect(() => {
    let interval = setInterval(() => {
      const [newTimer, percent] = calcTimerWidth();
      setTimer(newTimer);
      setTimerWidth(percent);
    }, 200);
    return () => clearInterval(interval);
  }, [calcTimerWidth]);

  return (
    <Box className={classes.corner}>
      <div
        className={classes.container}
        style={{ backgroundColor: theme.palette[type].main }}
      >
        <Hidden smDown>
          {options.type === "error" && (
            <ErrorOutlineIcon
              className={classes.icon}
              style={{ color: theme.palette[type].contrastText }}
            />
          )}
          {options.type === "success" && (
            <CheckCircleOutlineRoundedIcon
              className={classes.icon}
              style={{ color: theme.palette[type].contrastText }}
            />
          )}
          {options.type === "warning" && (
            <ReportOutlinedIcon
              className={classes.icon}
              style={{ color: theme.palette[type].contrastText }}
            />
          )}
          {options.type === "wifioff" && (
            <WifiOffIcon
              className={classes.icon}
              style={{ color: theme.palette.warning.contrastText }}
            />
          )}
          {options.type === "info" && (
            <HelpOutlineRoundedIcon
              className={classes.icon}
              style={{ color: theme.palette[type].contrastText }}
            />
          )}
        </Hidden>
        <div className={classes.comments}>
          <Typography
            variant="h6"
            style={{ color: theme.palette[type].contrastText }}
          >
            {options.title}
          </Typography>
          <Typography
            variant="body2"
            style={{ color: theme.palette[type].contrastText }}
          >
            {message}
          </Typography>
        </div>
        <CancelIcon
          style={{ color: theme.palette[type].contrastText }}
          className={classes.close}
          onClick={close}
        />
      </div>
      <span
        className={classes.timerLine}
        style={{
          width: `${timerWidth}%`,
          borderColor: theme.palette[type].dark,
        }}
      ></span>
    </Box>
  );
};

export default Corner;
