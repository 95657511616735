import React from "react";

import { useTranslation } from "react-i18next";

import { Stepper, Step, StepLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      "& .MuiStepLabel-labelContainer": {
        display: "none",
      },
    },
  },
  primary: {
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: theme.palette.primary.main,
        "&.MuiStepIcon-completed": {
          backgroundColor: theme.palette.primary.main,
          borderRadius: "100%",
        },
        "& .MuiStepIcon-text": {
          fill: theme.palette.primary.contrastText,
        },
      },
  },
  secondary: {
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: theme.palette.secondary.main,
        "& .MuiStepIcon-text": {
          fill: theme.palette.secondary.contrastText,
        },
      },
  },
  error: {
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: theme.palette.error.main,
        "& .MuiStepIcon-text": {
          fill: theme.palette.error.contrastText,
        },
      },
  },
  warning: {
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: theme.palette.warning.main,
        "& .MuiStepIcon-text": {
          fill: theme.palette.warning.contrastText,
        },
      },
  },
  info: {
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: theme.palette.info.main,
        "& .MuiStepIcon-text": {
          fill: theme.palette.info.contrastText,
        },
      },
  },
  success: {
    "& .MuiStepIcon-root.MuiStepIcon-active, & .MuiStepIcon-root.MuiStepIcon-completed":
      {
        color: theme.palette.success.main,
        "& .MuiStepIcon-text": {
          fill: theme.palette.success.contrastText,
        },
      },
  },
}));

const CotroliaStepper = ({ steps, activeStep, variant = "primary" }) => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Stepper alternativeLabel activeStep={activeStep} className={classes.root}>
      {steps.map((label) => (
        <Step key={label} className={classes[variant]}>
          <StepLabel>{t(label)}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CotroliaStepper;
