import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const FullPageLoader = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default FullPageLoader;
