import "./App.scss";
import "./services/multilingual";
import { useAuth } from "./context/auth-context";
import AuthenticatedApp from "./components/AuthenticatedApp";
import UnauthenticatedApp from "./components/UnauthenticatedApp";
import { ThemeProvider } from "@material-ui/core";
import { MenuProvider } from "./context/menu-context";
import FullPageLoader from "./components/core/FullPageLoader";
import { useConfiguration } from "./context/configuration-context";
import UnderMaintenance from "./components/core/UnderMaintenance";

function App() {
  const { user, isLoading } = useAuth();
  const configuration = useConfiguration();

  return (
    <>
      {configuration.loading ? (
        <FullPageLoader />
      ) : configuration.underMaintenance ? (
        <UnderMaintenance title={configuration.name} />
      ) : (
        <ThemeProvider theme={configuration.themes.theme}>
          <MenuProvider>
            {isLoading ? (
              <FullPageLoader />
            ) : user ? (
              <AuthenticatedApp />
            ) : (
              <UnauthenticatedApp />
            )}
          </MenuProvider>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
