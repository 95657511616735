import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    borderRadius: "0",
    backgroundColor: theme.palette.background.default,
  },
  containerPadding: {
    padding: theme.spacing(4, 15, 4, 15),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 2, 3, 2),
    },
  },
  containerPaddingWhithoutSide: {
    padding: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 0, 3, 0),
    },
  },
  content: {
    width: "100%",
    zIndex: theme.zIndex.drawer,
    display: "flex",
    flexDirection: "column",
  },
}));

const Content = ({ withoutPadding, children }) => {
  const classes = useStyle();

  return (
    <Box
      className={`${classes.container} ${
        withoutPadding
          ? classes.containerPaddingWhithoutSide
          : classes.containerPadding
      }`}
    >
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

export default Content;
