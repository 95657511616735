import API from "./api";

/**
 * Class to manage validation api call
 */
class ValidationService {
  constructor() {
    const api = new API();
    this.api = api;
  }

  successCallback = (status, data) => {
    return data;
  };

  /**
   * Method to check if vat number is valid
   */
  validateVat = async (vat) => {
    const data = await this.api
      .get(
        "/supercotrolia/validate/vatNumber?vatNumber=" + vat,
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });
    return data;
  };
}

export default new ValidationService();
