import { FormControl, makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: { width: "100%", margin: `${theme.spacing(1)}px 0` },
}));

const CotroliaFormControl = ({ additionalClass, ...props }) => {
  const classes = useStyle();
  return (
    <FormControl className={`${classes.root} ${additionalClass}`} {...props}>
      {props.children}
    </FormControl>
  );
};

export default CotroliaFormControl;
