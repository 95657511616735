/**
 * Capitalize a string.
 *
 * @param   {String}  s       Capitalize a string
 * @returns {String}          Capitalized string
 */
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Verify if an email is valid
 *
 * @param   {String}  email        Email to verify
 * @returns {boolean}
 */
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

/**
 * Verify if a string is numeric
 *
 * @param   {String}  num         String to verify
 * @returns {boolean}
 */
export const isNumeric = (num) => {
  const re = /^[0-9]*$/;
  return re.test(num);
};

/**
 * Verify if a password is valid.
 * TODO : change password validation rules
 *
 * @param   {String}  password    Password to verify
 * @returns {boolean}
 */
export const validatePassword = (password) => {
  return password.length >= 4;
};

/**
 * Check either if the siret is valid or not following this ruleset :
 *  - https://portal.hardis-group.com/pages/viewpage.action?pageId=120357227
 *
 * @param 	String    Siret to verify
 * @return 	boolean
 */
export const isSiretValid = (siret) => {
  let isValid;

  if (siret.length !== 14 || isNaN(siret)) {
    // SIRET must be 14 digits
    isValid = false;
  } else {
    // 9 first digits are SIREN (ou RCS), the 4 following
    // are the establishment number
    // last digit is a LUHN key.
    let sum = 0;
    let value;
    for (let cpt = 0; cpt < siret.length; cpt++) {
      if (cpt % 2 === 0) {
        value = siret.charAt(cpt) * 2; // Odd position are multiplied by 2
        if (value > 9) {
          value -= 9; // If the result is superior at 9, we substract 9
        }
      } else {
        value = siret.charAt(cpt); // Even position are multiplied by 1
      }
      sum += parseInt(value);
    }
    if (sum % 10 === 0) {
      // If the sum is a multiple of 10 then the SIRET is valid
      isValid = true;
    } else {
      isValid = false;
    }
  }
  return isValid;
};

/**
 * Transform a byte size into readable one
 *
 * @param {integer}   size        File size
 * @returns                       Formated size
 */
export const formatSize = (size) => {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = parseInt(size, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

/**
 * Check if the phone number is valid
 *
 * @param {number} phoneNumber
 * @returns
 */
export const validPhoneNumber = (phoneNumber) => {
  const re = /^([+]?!*([0-9]!*){10,})$/; // we can remove [+]? if we don't want to accept international phone number
  return re.test(phoneNumber);
};
