import { Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import ContentTwoSided, { LeftSide, RightSide } from "../core/ContentTwoSided";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import CotroliaTextField from "../core/input/CotroliaTextField";
import { emptyAddress, emptyError } from "../../utils/model";
import { capitalize } from "../../utils/validation";
import authService from "../../services/auth.service";
import { useAuth } from "../../context/auth-context";
import CotroliaVatInput from "../core/input/CotroliaVatInput";
import CotroliaAddress from "../core/input/CotroliaAddress";

const useStyle = makeStyles((theme) => ({
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    height: "56px",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
}));
const ProfileCompany = ({ thirdparty }) => {
  const { t } = useTranslation();
  const { user, updateUser } = useAuth();
  const alert = useAlert();
  const classes = useStyle();

  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(thirdparty.name ? thirdparty.name : "");
  const [nameError, setNameError] = useState(emptyError);
  const [vat, setVat] = useState(thirdparty.vat ? thirdparty.vat : "");
  const [vatError, setVatError] = useState(emptyError);
  const [phone, setPhone] = useState(thirdparty.phone ? thirdparty.phone : "");
  const [phoneError, setPhoneError] = useState(emptyError);
  const [address, setAddress] = useState(
    thirdparty.address ? thirdparty.address : {...emptyAddress}
  );
  const [addressError, setAddressError] = useState(emptyError);

  const hasError = () => {
    return (
      nameError.value ||
      phoneError.value ||
      vatError.value ||
      addressError.value
    );
  };

  const handleCancel = () => {
    setName(thirdparty.name);
    setNameError(emptyError);
    setVat(thirdparty.vat);
    setPhone(thirdparty.phone);
    setPhoneError(emptyError);
    setAddress(thirdparty.address);
    setAddressError(emptyError);
    setEditMode(false);
  };

  const handleSave = () => {
    authService
      .updateCompany(name, phone, { ...address }, vat)
      .then((res) => {
        alert.success("", { title: t("InformationModified") });
        setEditMode(false);

        // Update local user informations
        let userCopy = { ...user };
        userCopy.data.thirdparty.name = name;
        userCopy.data.thirdparty.vat = vat;
        userCopy.data.thirdparty.address = address;
        userCopy.data.thirdparty.phone = phone;
        updateUser(userCopy);
      })
      .catch((err) => {
        console.error(err);
        handleCancel();
        alert.error(err.message, { title: t("InternalError") });
      });
  };

  return (
    <>
      <ContentTwoSided>
        <LeftSide>
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("CompanyName")}
              value={name}
              disabled={!editMode}
              required={editMode}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setNameError({
                    value: true,
                    msg: t("FieldRequired"),
                  });
                } else {
                  setNameError(emptyError);
                }
                setName(capitalize(value));
              }}
              error={nameError.value}
              helperText={nameError.msg}
            />
          </CotroliaFormControl>
          <CotroliaFormControl>
            <CotroliaVatInput
              label={t("VatNumber")}
              value={vat}
              disabled={!editMode}
              required={editMode}
              setValue={setVat}
              setError={setVatError}
            />
          </CotroliaFormControl>
        </LeftSide>
        <RightSide>
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("Phone")}
              value={phone}
              disabled={!editMode}
              required={editMode}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setPhoneError({
                    value: true,
                    msg: t("FieldRequired"),
                  });
                } else {
                  setPhoneError(emptyError);
                }
                setPhone(value);
              }}
              error={phoneError.value}
              helperText={phoneError.msg}
            />
          </CotroliaFormControl>
            {/* Address */}
              <CotroliaAddress
                label={t("Address")}
                onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setAddressError({
                    value: true,
                    msg: t("FieldRequired"),
                  });
                } else {
                  setAddressError(emptyError);
                }
                setAddress(value);
                }}
                updateAddress={((value) => setAddress(value))}
                defaultAddress={address}
                disabled={!editMode}
                required={editMode}
                error={addressError.value}
                helperText={addressError.msg}
              />
          <CotroliaFormControl>
          </CotroliaFormControl>
        </RightSide>
      </ContentTwoSided>
      <CotroliaFormControl>
        <Box className={classes.actionContainer}>
          {editMode ? (
            <>
              <Button color="secondary" onClick={handleCancel}>
                {t("Cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={hasError()}
              >
                {t("Save")}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditMode(true)}
            >
              {t("Modify")}
            </Button>
          )}
        </Box>
      </CotroliaFormControl>
    </>
  );
};

export default ProfileCompany;
