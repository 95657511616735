import { Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import ContentTwoSided, { LeftSide, RightSide } from "../core/ContentTwoSided";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import CotroliaTextField from "../core/input/CotroliaTextField";
import authService from "../../services/auth.service";
import { useAuth } from "../../context/auth-context";
import { emptyError } from "../../utils/model";
import { capitalize, validateEmail } from "../../utils/validation";

const useStyle = makeStyles((theme) => ({
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    height: "56px",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const ProfileMainContact = ({ userData }) => {
  const { t } = useTranslation();
  const { user, updateUser } = useAuth();
  const alert = useAlert();
  const classes = useStyle();

  const [editMode, setEditMode] = useState(false);
  const [lastname, setLastname] = useState(userData.lastname);
  const [lastnameError, setLasnameError] = useState(emptyError);
  const [firstname, setFirstname] = useState(userData.firstname);
  const [firstnameError, setFirstnameError] = useState(emptyError);
  const [email, setEmail] = useState(userData.email);
  const [emailError, setEmailError] = useState(emptyError);

  const isValid = () => {
    let res =
      lastname !== "" &&
      firstname !== "" &&
      email !== "" &&
      !lastnameError.value &&
      !firstnameError.value &&
      !emailError.value;
    return res;
  };

  const handleCancel = () => {
    setEditMode(false);
    setLastname(userData.lastname);
    setFirstname(userData.firstname);
    setEmail(userData.email);
    setLasnameError(emptyError);
    setFirstnameError(emptyError);
    setEmailError(emptyError);
  };

  const handleSave = () => {
    authService
      .updateMe(lastname, firstname, email)
      .then((res) => {
        alert.success("", { title: t("InformationModified") });
        setEditMode(false);

        // Update local user informations
        let userCopy = { ...user };
        userCopy.data.lastname = lastname;
        userCopy.data.firstname = firstname;
        userCopy.data.email = email;
        updateUser(userCopy);
      })
      .catch((err) => {
        console.error(err);
        handleCancel();
        alert.error(err.message, { title: t("InternalError") });
      });
  };

  const handleChangePassword = () => {
    authService
      .getResetPasswordLink(email)
      .then(() => {
        alert.success("", { title: t("PasswordResetLinkSent") });
      })
      .catch((err) => {
        console.error(err);
        alert.error(t("TryAgainLater"), {
          title: t("MailNotSent"),
        });
      });
  };

  return (
    <>
      <ContentTwoSided>
        <LeftSide>
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("Lastname")}
              value={lastname}
              required={editMode}
              onChange={(e) => {
                const value = e.target.value.toUpperCase();
                if (value !== "") {
                  setLasnameError(emptyError);
                } else {
                  setLasnameError({
                    value: true,
                    msg: t("RequiredFieldLastname"),
                  });
                }
                setLastname(value);
              }}
              disabled={!editMode}
              error={lastnameError.value}
              helperText={t(lastnameError.msg)}
            />
          </CotroliaFormControl>

          <CotroliaFormControl>
            <CotroliaTextField
              label={t("Firstname")}
              value={firstname}
              required={editMode}
              onChange={(e) => {
                const value = capitalize(e.target.value);
                if (value !== "") {
                  setFirstnameError(emptyError);
                } else {
                  setFirstnameError({
                    value: true,
                    msg: t("RequiredFieldFirstname"),
                  });
                }
                setFirstname(value);
              }}
              disabled={!editMode}
              error={firstnameError.value}
              helperText={t(firstnameError.msg)}
            />
          </CotroliaFormControl>
        </LeftSide>
        <RightSide>
          <CotroliaFormControl>
            <CotroliaTextField
              label={t("Email")}
              value={email}
              required={editMode}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setEmailError({
                    value: true,
                    msg: t("RequiredFieldEmail"),
                  });
                } else if (!validateEmail(value)) {
                  setEmailError({
                    value: true,
                    msg: t("InvalidEmail"),
                  });
                } else {
                  setEmailError(emptyError);
                }
                setEmail(value);
              }}
              disabled={!editMode}
              error={emailError.value}
              helperText={t(emailError.msg)}
            />
          </CotroliaFormControl>

          <CotroliaFormControl>
            <Box className={classes.actionContainer}>
              {editMode ? (
                <>
                  <Button color="secondary" onClick={handleCancel}>
                    {t("Cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={!isValid()}
                  >
                    {t("Save")}
                  </Button>
                </>
              ) : (
                <>
                  <Button color="secondary" onClick={handleChangePassword}>
                    {t("ChangePassword")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setEditMode(true)}
                  >
                    {t("Modify")}
                  </Button>
                </>
              )}
            </Box>
          </CotroliaFormControl>
        </RightSide>
      </ContentTwoSided>
    </>
  );
};

export default ProfileMainContact;
