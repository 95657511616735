import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import validationService from "../../../services/validation.service";
import { emptyError } from "../../../utils/model";
import CotroliaTextField from "./CotroliaTextField";

const CotroliaVatInput = ({
  label,
  value,
  setValue,
  disabled,
  required,
  setError,
  isCotroliaInput = true,
  ...props
}) => {
  const [vatError, setVatError] = useState(emptyError);
  const [isLoading, setLoading] = useState(false);
  const [init, setInit] = useState(true);
  const { t } = useTranslation();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (!init) {
      if (value !== "") {
        // Delay search to avoid too many requests
        const delayDebounceFn = setTimeout(() => {
          setLoading(true);
          validationService
            .validateVat(value)
            .then((res) => {
              setVatError(emptyError);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              setVatError({
                value: true,
                msg: t("VatNotValid"),
              });
            });
        }, 300);

        return () => clearTimeout(delayDebounceFn);
      } else if (required) {
        setVatError({
          value: true,
          msg: t("VatRequired"),
        });
      }
    } else {
      setInit(false);
    }
  }, [t, value, required]);

  useEffect(() => setError(vatError), [vatError, setError]);

  return isCotroliaInput ? (
    <CotroliaTextField
      {...props}
      label={label}
      value={value}
      disabled={disabled}
      required={required}
      onChange={onChange}
      error={vatError.value}
      helperText={vatError.msg}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading && <CircularProgress color="primary" size={24} />}
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <TextField
      {...props}
      label={label}
      value={value}
      disabled={disabled}
      required={required}
      onChange={onChange}
      error={vatError.value}
      helperText={vatError.msg}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading && <CircularProgress color="primary" size={24} />}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CotroliaVatInput;
