import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  leftSide: {
    [theme.breakpoints.up("md")]: {
      flex: 1,
      marginRight: theme.spacing(2),
    },
  },
  rightSide: {
    [theme.breakpoints.up("md")]: {
      flex: 1,
      marginLeft: theme.spacing(2),
    },
  },
}));

export const LeftSide = ({ children }) => {
  const classes = useStyle();
  return <Box className={classes.leftSide}>{children}</Box>;
};

export const RightSide = ({ children }) => {
  const classes = useStyle();
  return <Box className={classes.rightSide}>{children}</Box>;
};

export const ContentTwoSided = ({ children }) => {
  const classes = useStyle();

  return <Box className={classes.root}>{children}</Box>;
};

export default ContentTwoSided;
