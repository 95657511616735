import API from "./api";
import interventionService from "./intervention.service";

/**
 * Class to manage Notifications api call
 */
class NotificationService {
  constructor() {
    const api = new API();
    this.api = api;
  }

  successCallback = (status, data) => {
    return data;
  };

  /**
   * Method to get all notification for current user
   */
  getAllNotification = async () => {
    const data = await this.api
      .get("/supercotrolia/notifications", this.successCallback)
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   * Method to mark as read a notification
   */
  readNotification = async (id) => {
    const response = await this.api
      .post("/supercotrolia/notifications/" + id, null, this.successCallback)
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Format a file list to the API format
   *
   * @param {array} files     List of files to format
   * @returns                 List of files formated
   */
  _formatFiles = async (files) => {
    let newFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const filecontent = await interventionService.toBase64(file);
      newFiles.push({ filename: file.name, filecontent });
    }

    return newFiles;
  };

  /**
   * Send and email to contact a service
   *
   * @param {string}  service    Service to contact
   * @param {string}  content    Content of the message
   * @param {array}   files      List of files to join
   * @returns                    Api response
   */
  sendEmail = async (service, content, files) => {
    files = await this._formatFiles(files);

    const response = await this.api
      .post(
        "/supercotrolia/contact/",
        {
          service,
          content: content.replace(/\n/g, "<br />"),
          files,
        },
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };
}

export default new NotificationService();
