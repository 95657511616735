import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
  textfield: {
    // Label Color
    "& .MuiInputLabel-filled": {
      color: theme.palette.primary.main,
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },

    // Input form
    "& .MuiFilledInput-root": {
      borderRadius: theme.spacing(1) + 2,
      backgroundColor: theme.palette.grey[50],
      "&::before": {
        borderBottom: "none",
      },
      "&:hover:before": {
        borderBottom: "none",
      },
      // Border bottom of the input
      "&.MuiFilledInput-underline": {
        "&:after": {
          width: `calc(100% - ${(theme.spacing(1) + 2) * 2}px)`, // We substract border-radius of each corner
          left: theme.spacing(1) + 2, // We center the line
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        "&.Mui-error:after": {
          borderColor: theme.palette.error.main,
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.text.primary,
        },
      },
    },

    // Border radius on the shadow on focus
    "& .MuiSelect-select:focus": {
      borderRadius: theme.spacing(1) + 2,
    },
  },
}));

const CotroliaTextField = (props) => {
  const classes = useStyle();
  return (
    <TextField
      className={`${classes.textfield} ${props.className}`}
      variant="filled"
      {...props}
    >
      {props.children}
    </TextField>
  );
};

export default CotroliaTextField;
