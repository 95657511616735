import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";

import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  Divider,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";

import SelectOrAddContact from "../core/SelectOrAddContact";
import AddContactForm from "../core/AddContactForm";
import authService from "../../services/auth.service";
import AppConstant from "../../utils/appConstant";
import { Popup, PopupCard } from "../core/Popup";
import { useAuth } from "../../context/auth-context";

const useStyle = makeStyles((theme) => ({
  listEntryContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1) + 2,
    position: "relative",
  },
  entryContainer: {
    display: "flex",
    alignItems: "center",
    padding: `0 ${theme.spacing(2)}px`,
  },
  entryLabel: {
    flexGrow: 1,
  },
  entryBtn: {
    color: theme.palette.text.secondary,
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const ContactEntry = ({ id, lastname, firstname, email, phone, fk_user }) => {
  const classes = useStyle();
  const alert = useAlert();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const { user, updateUser } = useAuth();
  const { i18n } = useTranslation();

  const handleDelete = () => {
    authService
      .deleteContactRole(id, AppConstant.typeContactTech)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        alert.error(t("TryAgainLater"), {
          title: t("TechContactNotDeleted"),
        });
      });
  };

  const handleEdit = (lastname, firstname, email, phone) => {
    authService
      .updateContact(id, lastname, firstname, email, phone)
      .then(() => window.location.reload())
      .catch((err) => {
        console.error(err);
        alert.error(t("TryAgainLater"), {
          title: t("ContactNotModified"),
        });
      });
    setEditMode(false);
  };

  const handleCreateAccount = () => {
    authService
      .createAccount(id, i18n.language)
      .then((res) => {
        alert.success(`${t("MailSent")} ${email} ${t("ToCreatePassword")}`, {
          title: t("AccountCreated"),
        });

        // Update local user informations
        const userCopy = { ...user };
        let newContactList = [];
        userCopy.data.thirdparty.contactList.forEach((contact) => {
          if (contact.id === id) {
            contact.fk_user = res.data.id;
          }
          newContactList.push(contact);
        });
        userCopy.data.thirdparty.contactList = newContactList;
        updateUser(userCopy);
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          alert.error(t("RegisterErrorEmailAlreadyExists"), {
            title: t("AccountNotCreated"),
          });
        } else {
          alert.error(t("TryAgainLater"), {
            title: t("AccountNotCreated"),
          });
        }
      });
  };

  const handleDeleteAccount = () => {
    authService
      .deleteAccount(fk_user)
      .then(() => {
        alert.success("", {
          title: t("AccountDeleted"),
        });

        // Update local user informations
        const userCopy = { ...user };
        let newContactList = [];
        userCopy.data.thirdparty.contactList.forEach((contact) => {
          if (contact.id === id) {
            contact.fk_user = null;
          }
          newContactList.push(contact);
        });
        userCopy.data.thirdparty.contactList = newContactList;
        updateUser(userCopy);
      })
      .catch((err) => {
        console.error(err);
        alert.error(t("TryAgainLater"), {
          title: t("AccountNotDeleted"),
        });
      });
  };

  const handlePermanentDelete = () => {
    authService
      .deleteContact(id)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        alert.error(t("TryAgainLater"), { title: t("ContactNotDeleted") });
      });
  };

  return (
    <>
      <Box className={classes.entryContainer}>
        <PersonIcon />
        <Typography variant="body1" className={classes.entryLabel}>
          {lastname + " " + firstname}
        </Typography>
        {fk_user === null ? (
          <Tooltip title={t("CreateAccount")}>
            <IconButton
              className={classes.entryBtn}
              onClick={handleCreateAccount}
            >
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        ) : user.data.email === email ? (
          <Tooltip title={t("MainAccountNotDeletable")}>
            <span>
              <IconButton className={classes.entryBtn} disabled>
                <PersonAddDisabledIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={t("DeleteAccount")}>
            <IconButton
              className={classes.entryBtn}
              onClick={handleDeleteAccount}
            >
              <PersonAddDisabledIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("EditContact")}>
          <IconButton
            className={classes.entryBtn}
            onClick={() => setEditMode(!editMode)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("UnlinkContact")}>
          <IconButton className={classes.entryBtn} onClick={handleDelete}>
            <LinkOffIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {editMode && (
        <Popup>
          <PopupCard
            title={t("EditTechContact")}
            description={
              t("EditTechContactDesc") +
              " : " +
              lastname +
              " " +
              firstname +
              " <" +
              email +
              ">"
            }
          >
            <AddContactForm
              lastname={lastname}
              firstname={firstname}
              email={email}
              phone={phone}
              addBtnLabel={t("Modify")}
              handleAdd={handleEdit}
              handleCancel={() => setEditMode(false)}
            />
          </PopupCard>
          {user.data.email !== email && (
            <PopupCard
              title={t("DeleteContact")}
              description={
                t("DeleteContactDesc") +
                " : " +
                lastname +
                " " +
                firstname +
                " <" +
                email +
                ">"
              }
              variant="outlined"
              color="secondary"
              action={handlePermanentDelete}
              actionLabel={t("Delete")}
              actionIcon={<DeleteIcon />}
            ></PopupCard>
          )}
        </Popup>
      )}
    </>
  );
};

const ProfileTechnicalContact = ({ contacts }) => {
  const classes = useStyle();
  const alert = useAlert();
  const { t } = useTranslation();
  const { user, updateUser } = useAuth();

  const [addMode, setAddMode] = useState(false);
  const [contactList, setContactList] = useState(contacts);

  const handleAdd = (id) => {
    authService
      .addContactRole(id, AppConstant.typeContactTech)
      .then((res) => {
        alert.success("", { title: t("TechContactAdded") });

        // Update local user informations
        let userCopy = { ...user };
        userCopy.data.thirdparty.contactList = [
          ...userCopy.data.thirdparty.contactList,
          res.data,
        ];
        updateUser(userCopy);

        // Update local props list
        setContactList([...contactList, res.data]);
      })
      .catch((err) => {
        console.error(err);
        alert.error(t("TryAgainLater"), { title: t("TechContactNotAdded") });
      });
  };

  const handleCreate = (lastname, firstname, email, phone) => {
    authService
      .createContact(
        lastname,
        firstname,
        email,
        phone,
        AppConstant.typeContactTech
      )
      .then((res) => {
        alert.success("", { title: t("TechContactAdded") });

        // Update local user informations
        let userCopy = { ...user };
        userCopy.data.thirdparty.contactList = [
          ...userCopy.data.thirdparty.contactList,
          res.data,
        ];
        updateUser(userCopy);

        // Update local props list
        setContactList([...contactList, res.data]);

        setAddMode(false);
      })
      .catch((err) => {
        console.error(err);

        if (err.response.status === 403) {
          alert.error(t("TryAgainLater"), {
            title: t("TechContactNotAdded403"),
          });
        } else {
          alert.error(t("TryAgainLater"), {
            title: t("TechContactNotAdded"),
          });
        }
      });
  };

  return (
    <>
      <Box className={classes.margin}>
        <SelectOrAddContact
          required={false}
          selected=""
          onSelect={handleAdd}
          onClick={() => setAddMode(true)}
          excludeList={contactList}
        />
        {addMode && (
          <AddContactForm
            handleAdd={handleCreate}
            handleCancel={() => setAddMode(false)}
          />
        )}
      </Box>
      <Divider />
      <Box className={classes.margin}>
        <Box className={classes.listEntryContainer}>
          {contactList.map((contact, index) => (
            <ContactEntry key={index} {...contact} />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ProfileTechnicalContact;
