import React, { useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import DividerWithIcon from "./DividerWithAction";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router";
import AppConstant from "../../utils/appConstant";
import { useAuth } from "../../context/auth-context";
import interventionService from "../../services/intervention.service";
import displayCards from "../../utils/intervention";
import NoIntervention from "./NoIntervention";

const useStyle = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${theme.spacing(30)}px)`, // We need to substract the side padding
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(4)}px)`, // We need to substract the side padding
    },
  },
  containerPaddingSide: {
    padding: theme.spacing(0, 15, 0, 15),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2, 0, 2),
    },
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(2, 0),
    justifyContent: "center",
    "& .MuiCard-root": {
      [theme.breakpoints.up("sm")]: {
        width: AppConstant.interventionCardWidthList,
      },
    },
  },
}));

const InterventionDashboardList = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [interventionNb, setInterventionNb] = useState(null);
  const [interventions, setInterventions] = useState(null);
  const { user } = useAuth();
  const CARD_LIMIT = 3;

  useEffect(() => {
    interventionService
      .listIntervention(CARD_LIMIT, 1, null, { tms: "DESC" })
      .then((res) => {
        setLoading(false);
        if (res.data) {
          setInterventions(res.data.results);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (user.data && user.data.thirdparty) {
      setInterventionNb(user.data.thirdparty.ongoingInterNb);
    }
  }, [user]);

  const handleAction = () => {
    history.push("/intervention/ongoing");
  };

  return (
    <Box className={`${classes.root} ${classes.containerPaddingSide}`}>
      <DividerWithIcon
        title={t("MyLastIntervention")}
        icon={<ArrowForwardIcon />}
        action={handleAction}
        actionName={t("OnGoing")}
      />

      {interventionNb > 0 ? (
        <Box className={classes.cardContainer}>
          {displayCards(
            isLoading,
            interventionNb > CARD_LIMIT ? CARD_LIMIT : interventionNb,
            interventions,
            history
          )}
        </Box>
      ) : (
        <NoIntervention />
      )}
    </Box>
  );
};

export default InterventionDashboardList;
