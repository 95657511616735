import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: theme.spacing(0.5),
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    "& .MuiAccordionSummary-expandIcon": {
      color: theme.palette.primary.main,
    },
  },
  title: {
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    "& > svg": {
      marginRight: theme.spacing(1),
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  desc: {
    color: theme.palette.text.secondary,
    opacity: 0.64,
    "& > *": {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
  details: {
    flexDirection: "column",
  },
}));

const ToggleSection = ({
  title,
  icon,
  description,
  defaultExpanded,
  children,
  ...props
}) => {
  const classes = useStyle();
  return (
    <Accordion className={classes.container} defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className={classes.header}>
          <Box className={classes.title}>
            {icon}
            <Typography variant="h6">{title}</Typography>
          </Box>
          <Box className={classes.desc}>
            <Typography variant="subtitle1">{description}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default ToggleSection;
