import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../core/Header";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { useHistory } from "react-router";

const Catalog = ({ pdfName }) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    var win = window.open(pdfName, "_blank");
    if (win !== null) {
      history.goBack();
      win.focus();
    }
  }, [pdfName, history]);

  return (
    <>
      <Header
        title={t("PDFCatalog")}
        titleIcon={<AttachmentIcon />}
        titleDesc=""
      ></Header>
    </>
  );
};

export default Catalog;
