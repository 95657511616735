import { useTheme } from "@material-ui/core";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import LoginOrRegister from "./auth/LoginOrRegister";
import ValidateAccount from "./auth/ValidateAccount";
import Footer from "./core/Footer";
import { ResetPassword } from "./pages";

const UnauthenticatedApp = () => {
  const theme = useTheme();

  return (
    <div
      className="App"
      style={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Router>
        <Switch>
          <Route exact path={["/login", "/"]}>
            <LoginOrRegister variant="login" />
          </Route>
          <Route path="/register">
            <LoginOrRegister variant="register" />
          </Route>
          <Route path="/forget-password">
            <LoginOrRegister variant="forget-password" />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/validate-account">
            <ValidateAccount />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
      <Footer />
    </div>
  );
};

export default UnauthenticatedApp;
