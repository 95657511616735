import { Box, Button, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BackupIcon from "@material-ui/icons/Backup";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { useEffect } from "react";
import { formatSize } from "../../utils/validation";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  btn: {
    width: "100%",
  },
  input: {
    display: "none",
  },
  listEntryContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1) + 2,
    position: "relative",
  },
  listEntryContainerError: {
    "&::before": {
      content: "' '",
      borderWidth: "80px",
      position: "absolute",
      left: "10px",
      bottom: 0,
      height: "2px",
      width: "calc(100% - 20px)",
      borderBottom: "2px solid " + theme.palette.error.main,
    },
  },
  entryContainer: {
    display: "flex",
    alignItems: "center",
    padding: `0 ${theme.spacing(2)}px`,
  },
  entryLabel: {
    flexGrow: 1,
  },
  entryBtn: {
    color: theme.palette.text.secondary,
  },
  errorContainer: {
    margin: `0 ${theme.spacing(2)}px`,
  },
}));

const DocumentEntry = ({ index, file, removeFile }) => {
  const classes = useStyle();

  const handleClick = () => {
    removeFile(index);
  };

  return (
    <Box className={classes.entryContainer}>
      <Typography variant="body2" className={classes.entryLabel}>
        {file.name}
      </Typography>
      <Typography variant="body2">{formatSize(file.size)}</Typography>
      <IconButton className={classes.entryBtn} onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

const UploadButton = ({
  parentFiles,
  setParentFiles,
  label,
  error,
  errorMsg,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [files, setFiles] = useState(parentFiles);

  useEffect(() => {
    setFiles(parentFiles);
  }, [parentFiles]);

  const handleChange = (event) => {
    const selectedFiles = event.target.files;

    let fileList = files.slice();
    for (let i = 0; i < selectedFiles.length; i++) {
      fileList.push(selectedFiles[i]);
    }

    setFiles(fileList);
    setParentFiles(fileList);
  };

  const removeFile = (index) => {
    let fileList = files.slice();
    fileList.splice(index, 1);
    setFiles(fileList);
    setParentFiles(fileList);
  };

  return (
    <Box className={classes.root}>
      <input
        accept="image/*,.pdf"
        className={classes.input}
        id="upload-file-input"
        type="file"
        multiple
        onChange={handleChange}
      />
      <label htmlFor="upload-file-input">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<BackupIcon />}
          className={classes.btn}
          component="span"
        >
          {label ? label : t("UploadDocument")}
        </Button>
      </label>

      <Box
        className={`${classes.listEntryContainer} ${
          error ? classes.listEntryContainerError : ""
        }`}
      >
        {files &&
          files.map((item, index) => (
            <DocumentEntry
              key={index}
              index={index}
              file={item}
              removeFile={removeFile}
            ></DocumentEntry>
          ))}
      </Box>

      <Typography
        variant="caption"
        color="error"
        className={classes.errorContainer}
      >
        {errorMsg}
      </Typography>
    </Box>
  );
};

export default UploadButton;
