import { makeStyles } from "@material-ui/core";
import React from "react";
import { useConfiguration } from "../../context/configuration-context";

const useStyle = makeStyles((theme) => ({
  miniPicture: {
    maxWidth: "40px",
    maxHeight: "40px",
  },
  fullPicture: {
    maxWidth: "240px",
    maxHeight: "240px",
  },
}));

const CompanyLogo = ({ variant, declination }) => {
  const classes = useStyle();
  const configuration = useConfiguration();
  let logo = "";
  let logoSmall = "";

  if (declination === "dark") {
    logo = configuration.logoDark;
    logoSmall = configuration.logoDarkSmall;
  } else {
    logo = configuration.logoLight;
    logoSmall = configuration.logoLightSmall;
  }

  return (
    <>
      {variant === "mini" ? (
        <img
          src={logoSmall}
          alt="Company Logo Mini"
          className={classes.miniPicture}
        ></img>
      ) : (
        <img
          src={logo}
          alt="Company Logo Full"
          className={classes.fullPicture}
        ></img>
      )}
    </>
  );
};

export default CompanyLogo;
