import React, { useEffect, useState } from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";

import Fancybox from "../../core/Fancybox";
import logoPDF from "../../../assets/img/fichier-pdf.png";

const useStyle = makeStyles((theme) => ({
  documentContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  documentEntry: {
    flexGrow: "1",
    order: "1",
    minWidth: "300px",
    flexBasis: "29%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.grey[50],
    borderRadius: "10px",
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    "&:hover": {
      cursor: "pointer",
    },
  },
  documentEntryPdf: {
    order: "2",
    flexBasis: "100%",
  },
  preview: {
    maxWidth: "50%",
  },
}));

const InterventionDetailDocumentEntry = ({ file }) => {
  const classes = useStyle();
  const [preview, setPreview] = useState(null);
  const [fileContent, setFileContent] = useState(null);

  useEffect(() => {
    if (file) {
      let component = null;
      let content = null;
      switch (file.extension) {
        case "pdf":
          setFileContent(
            "data:application/" + file.extension + ";base64," + file.content
          );
          component = (
            <img src={logoPDF} alt={file.name} className={classes.preview} />
          );
          break;
        case "png":
        case "jpg":
        case "jpeg":
        case "svg":
        default:
          content = "data:image/" + file.extension + ";base64," + file.content;
          setFileContent(content);
          component = (
            <img src={content} alt={file.name} className={classes.preview} />
          );
          break;
      }

      setPreview(component);
    }
  }, [file, classes]);

  return (
    <Box
      className={`${classes.documentEntry} ${
        file.extension === "pdf" ? classes.documentEntryPdf : ""
      }`}
      data-fancybox
      data-type={file.extension === "pdf" ? "iframe" : ""}
      data-src={fileContent}
      data-caption={file.name}
    >
      {preview}
      <Box m={2}>
        <Typography variant="body2" style={{ textAlign: "center" }}>
          {file.name}
        </Typography>
      </Box>
    </Box>
  );
};

/**
 * Content of the document section
 *
 * @param   {Object}  intervention     Intervention sent by the api
 * @returns                            Component content
 */
const InterventionDetailDocument = ({ intervention }) => {
  const classes = useStyle();

  return intervention ? (
    <Fancybox options={{ infinite: false }}>
      <Box className={classes.documentContainer}>
        {intervention.documents.map((item, idx) => (
          <InterventionDetailDocumentEntry file={item} key={idx} />
        ))}
      </Box>
    </Fancybox>
  ) : (
    <Skeleton height={400} />
  );
};

export default InterventionDetailDocument;
