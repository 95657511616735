import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useMenu } from "../../../context/menu-context";

import interventionService from "../../../services/intervention.service";
import Content from "../../core/layout/Content";
import Header from "../../core/Header";
import HeaderActions from "../../core/HeaderActions";
import MemoryIcon from "@material-ui/icons/Memory";
import ContentCard from "../../core/layout/ContentCard";
import NewInterventionStepSkeleton from "../../core/skeleton/NewInterventionStepSkeleton";
import StepChoice from "./StepChoice";
import moment from "moment";
import AppConstant from "../../../utils/appConstant";
import StepConfirm from "./StepConfirm";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  titleRightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: theme.spacing(2),
  },
}));

const InterventionInvoice = () => {
  const MIN_STEP = 1;
  const MAX_STEP = 2;
  const STEP_CHOICE = MIN_STEP;
  const STEP_CONFIRM = MAX_STEP;
  const steps = ["InvoiceStepChoice", "InvoiceStepConfirm"];

  const { id } = useParams();
  const { t } = useTranslation();
  const alert = useAlert();
  const menu = useMenu();
  const classes = useStyle();
  const history = useHistory();

  const [data, setData] = useState(null);
  const [choice, setChoice] = useState("");
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(MIN_STEP);

  const prevStep = () => {
    if (step > MIN_STEP) {
      setStep(step - 1);
    }
  };

  const handleNext = () => {
    if (choice !== AppConstant.invoiceChoiceCB) {
      setStep(step + 1);
    } else {
      // Update payment method
      interventionService
        .setPaymentMethod(id, choice)
        .then(() => {
          // Redirect to online payment
          window.location.href = data.onlinePaymentUri;
        })
        .catch((error) => {
          console.error(error);
          alert.error(t("TryAgainLater"), {
            title: t("InterventionInvoicePaymentMethodError"),
          });
        });
    }
  };

  const handleValidate = () => {
    // Update payment method
    interventionService
      .setPaymentMethod(id, choice)
      .then(() => {
        // Display feedback
        let title = "";
        let msg = "";
        if (choice === AppConstant.invoiceChoiceBankTransfer) {
          title = t("InterInvoiceBankTransferSuccess");
          msg = t("InterInvoiceBankTransferSuccessDesc");
        } else {
          title = t("InterInvoiceChequeSuccess");
          msg = t("InterInvoiceChequeSuccessDesc");
        }
        alert.show(msg, {
          title: title,
          close: () => {
            alert.remove(alert);
          },
          type: "success",
        });

        // Redirect to intervention view
        history.replace("/intervention/" + id);
      })
      .catch((error) => {
        console.error(error);
        alert.error(t("TryAgainLater"), {
          title: t("InterventionInvoicePaymentMethodError"),
        });
      });
  };

  useEffect(() => {
    // Get invoice datas
    interventionService
      .getInterventionInvoice(id)
      .then((res) => {
        // Verify if we are in the right step
        if (
          res.interStatus &&
          parseInt(res.interStatus) !== AppConstant.interStatusPaymentPending
        ) {
          alert.show(t("InterInvoiceNotAvailableDesc"), {
            title: t("InterInvoiceNotAvailable"),
            close: () => {
              alert.remove(alert);
            },
            type: "error",
          });
          // Redirect to intervention view
          history.replace("/intervention/" + id);
        }

        // Verify if invoice not already validated
        if (parseInt(res.paymentMethod) > 0) {
          alert.show(t("InterInvoiceChoiceAlredyDoneDesc"), {
            title: t("InterInvoiceChoiceAlredyDone"),
            close: () => {
              alert.remove(alert);
            },
            type: "error",
          });
          // Redirect to intervention view
          history.replace("/intervention/" + id);
        }

        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert.show(t("InterNotFoundDesc"), {
          title: t("InterNotFound"),
          close: () => {
            alert.remove(alert);
          },
          type: "error",
        });
      });
  }, [id, alert, t, history]);

  const displayStep = () => {
    switch (step) {
      case STEP_CHOICE:
        return (
          <StepChoice
            choice={choice}
            setChoice={setChoice}
            data={data}
            nextStep={handleNext}
            steps={steps}
            activeStep={step - 1}
          />
        );
      case STEP_CONFIRM:
        return (
          <StepConfirm
            choice={choice}
            data={data}
            steps={steps}
            activeStep={step - 1}
            prevStep={prevStep}
            validate={handleValidate}
          />
        );
      default:
        return <div>Step {step} not implemented</div>;
    }
  };

  return (
    <>
      <Header focus={true} variant="secondary">
        <HeaderActions>
          <Box display="flex" alignItems="center">
            <Box className={classes.titleRightContainer}>
              <Typography variant="h4">
                {data ? (
                  data.interRef + " " + t("Invoice")
                ) : (
                  <Skeleton width={200} />
                )}
              </Typography>
              <Typography variant="subtitle1">
                {data ? (
                  t("YourRef") +
                  " : " +
                  (data.interCustomerRef
                    ? data.interCustomerRef
                    : t("NoCustomerRef"))
                ) : (
                  <Skeleton width={250} />
                )}
              </Typography>
              <Typography variant="subtitle1">
                {data ? (
                  t("Date") +
                  " : " +
                  (data.invoiceDate
                    ? moment.unix(data.invoiceDate).format("ll")
                    : t("NoDate"))
                ) : (
                  <Skeleton width={250} />
                )}
              </Typography>
            </Box>
            {!menu.onSmartphone && <MemoryIcon />}
          </Box>
        </HeaderActions>
      </Header>
      <Content>
        <ContentCard>
          {loading ? <NewInterventionStepSkeleton /> : displayStep()}
        </ContentCard>
      </Content>
    </>
  );
};

export default InterventionInvoice;
