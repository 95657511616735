import axios from "axios";
import cipherService from "./cipher.service";

class Api {
  constructor() {
    let api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      responseType: "json",
      withCredentials: true,
    });
    api.interceptors.response.use(this.handleSuccess, this.handleError);

    let token = localStorage.getItem("token");
    if (token !== null) {
      try {
        token = cipherService.decrypt(token);
        api.defaults.headers.common = { Authorization: `Bearer ${token}` };
      } catch (e) {
        localStorage.removeItem("token"); // Delete the token if there are decrypt errors
      }
    }

    this.api = api;
  }

  setHeader(headers) {
    this.api.defaults.headers.common = headers;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    let token = localStorage.getItem("token");
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // Redirect to login
          if (
            token !== null &&
            !["/", "/login", "/register", "/forget-password"].includes(
              window.location.pathname
            )
          ) {
            localStorage.removeItem("token");
            window.location.href = "/login";
          }
          break;
        default:
          console.error("ERR " + error.response.status);
          break;
      }
    }
    return Promise.reject(error);
  };

  redirectTo = (document, path) => {
    document.location = path;
  };

  async get(path, callback) {
    const response = await this.api.get(path);
    return callback(response.status, response.data);
  }

  async put(path, payload, callback) {
    const response = await this.api.put(path, payload);
    return callback(response.status, response.data);
  }

  async post(path, payload, callback) {
    return this.api
      .request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload,
      })
      .then((response) => callback(response.status, response.data));
  }

  async delete(path, callback) {
    const response = await this.api.delete(path);
    return callback(response.status, response.data);
  }
}

export default Api;
