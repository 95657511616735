import React, { useState } from "react";
import {
  makeStyles,
  Box,
  FormControl,
  TextField,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useTranslation } from "react-i18next";
import { capitalize, validateEmail } from "../../utils/validation";
import CotroliaVatInput from "../core/input/CotroliaVatInput";

const useStyle = makeStyles((theme) => ({
  container: {
    background: theme.palette.paper,
    justifyContent: "center",
    width: "30vw",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      alignSelf: "unset",
    },
  },
  title: {
    margin: `${theme.spacing(2)}px 0`,
  },
  desc: {
    color: theme.palette.grey[500],
    margin: `${theme.spacing(2)}px 0`,
  },
  inputContainer: {
    width: "100%",
    margin: `${theme.spacing(1)}px 0`,
  },
  btn: {
    width: "100%",
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
}));

const emptyError = {
  value: false,
  msg: "",
};

const RegisterFormStep1 = ({ handleNext, values, handlers }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [nameError, setNameError] = useState(emptyError);
  const [firstnameError, setFirstNameError] = useState(emptyError);
  const [emailError, setEmailError] = useState(emptyError);
  const [thirdpartyError, setThirdpartyError] = useState(emptyError);
  const [vatError, setVatError] = useState(emptyError);

  const handleCheck = () => {
    handlers.setProfessional(!values.professional);
  };

  const isDisabled = () => {
    let ret = false;
    if (
      nameError.value ||
      firstnameError.value ||
      emailError.value ||
      thirdpartyError.value ||
      vatError.value
    ) {
      ret = true;
    }
    return ret;
  };

  const handleClick = () => {
    const mandatoryFields = [
      { name: "name", errorHandler: setNameError },
      { name: "firstname", errorHandler: setFirstNameError },
      { name: "email", errorHandler: setEmailError },
    ];

    const errMsg = t("MissingMandatoryFields");
    let error = false;

    mandatoryFields.forEach((e) => {
      if (values[e.name] === "") {
        error = true;
        e.errorHandler({
          value: true,
          msg: errMsg,
        });
      }
    });

    // Mandatory fields if professional is checked only
    if (values.professional) {
      const proMandatoryFields = [
        { name: "thirdparty", errorHandler: setThirdpartyError },
        { name: "vat", errorHandler: setVatError },
      ];

      proMandatoryFields.forEach((e) => {
        if (values[e.name] === "") {
          error = true;
          e.errorHandler({
            value: true,
            msg: errMsg,
          });
        }
      });
    }

    if (!error) {
      handleNext();
    }
  };

  return (
    <>
      <FormControl className={classes.inputContainer}>
        <TextField
          id="name"
          label={t("YourName")}
          variant="filled"
          color="secondary"
          value={values.name}
          onChange={(e) => {
            setNameError(emptyError);
            handlers.setName(e.target.value.toUpperCase());
          }}
          error={nameError.value}
          helperText={nameError.value ? nameError.msg : null}
          required
        />
      </FormControl>

      <FormControl className={classes.inputContainer}>
        <TextField
          id="firstname"
          label={t("YourFirstname")}
          variant="filled"
          color="secondary"
          value={values.firstname}
          onChange={(e) => {
            setFirstNameError(emptyError);
            handlers.setFirstname(capitalize(e.target.value));
          }}
          error={firstnameError.value}
          helperText={firstnameError.value ? firstnameError.msg : null}
          required
        />
      </FormControl>

      <FormControl className={classes.inputContainer}>
        <TextField
          id="email"
          label={t("YourEmail")}
          variant="filled"
          color="secondary"
          aria-describedby="email-helper"
          value={values.email}
          onChange={(e) => {
            const value = e.target.value;
            if (validateEmail(value)) {
              setEmailError(emptyError);
            } else {
              setEmailError({
                value: true,
                msg: t("InvalidEmail"),
              });
            }
            handlers.setEmail(e.target.value);
          }}
          error={emailError.value}
          helperText={emailError.value ? emailError.msg : null}
          required
        />
        <FormHelperText id="email-helper">
          {t("YourEmailHelper")}
        </FormHelperText>
      </FormControl>

      <Box className={classes.inputContainer}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.professional}
              onChange={handleCheck}
              name="professional"
              color="primary"
            />
          }
          label={t("Professional")}
        />
      </Box>

      {values.professional && (
        <>
          <FormControl className={classes.inputContainer}>
            <TextField
              id="company"
              label={t("Company")}
              variant="filled"
              color="secondary"
              value={values.thirdparty}
              onChange={(e) => {
                setThirdpartyError(emptyError);
                handlers.setThirdparty(capitalize(e.target.value));
              }}
              error={thirdpartyError.value}
              helperText={thirdpartyError.value ? thirdpartyError.msg : null}
              required
            />
          </FormControl>

          <FormControl className={classes.inputContainer}>
            <CotroliaVatInput
              id="siret"
              label={t("VatNumber")}
              value={values.vat}
              required
              setValue={handlers.setVat}
              setError={setVatError}
              isCotroliaInput={false}
              variant="filled"
              color="secondary"
            />
          </FormControl>
        </>
      )}

      <Button
        variant="contained"
        color="secondary"
        className={classes.btn}
        endIcon={<ArrowForwardIcon />}
        onClick={handleClick}
        disabled={isDisabled()}
      >
        {t("Next")}
      </Button>
    </>
  );
};

export default RegisterFormStep1;
