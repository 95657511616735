import React, { useState } from "react";
import {
  InputAdornment,
  IconButton,
  FormControl,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

/**
 * Component to show a password input with left button to trigger show/hide password
 *
 * @param   className   css class name
 * @param   label       label of the input field
 * @param   helper      field helper to show under field box
 * @param   value       field value
 * @param   handler     function to set new field value
 * @param   error       true to print @helper in error mode
 * @returns
 */
const CotroliaPassword = ({
  className,
  label,
  helper,
  value,
  handler,
  error,
  helperText,
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    const value = show ? false : true;
    setShow(value);
  };

  return (
    <FormControl className={className}>
      <TextField
        label={label}
        type={show ? "text" : "password"}
        variant="filled"
        color="secondary"
        onChange={(e) => handler(e.target.value)}
        value={value}
        error={error}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="default"
                aria-label="toggle password visibility"
                onClick={() => handleShow()}
              >
                {show ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText>{helper}</FormHelperText>
    </FormControl>
  );
};

export default CotroliaPassword;
