import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Step,
  StepLabel,
  Stepper,
  makeStyles,
  withStyles,
  StepConnector,
} from "@material-ui/core";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

import AppConstant from "../../../utils/appConstant";
import { Test } from "../../../utils/appConstant";
import {
  Clear,
  HourglassEmpty,
  Publish,
  Check,
  Save,
  LocalAtm,
  Settings,
  GetApp,
  Warning,
} from "@material-ui/icons";

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 35,
    height: 35,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  completed: {
    backgroundColor: theme.palette.success.main,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  active: {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
    backgroundColor: theme.palette.background.paper,
  },
}));

function CustomIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  
  const icons = {
    [AppConstant.interStatusRegistered]: <Save />,
    [AppConstant.interStatusTransmited]: <HourglassEmpty />,
    [AppConstant.interStatusToSend]: <MoveToInboxIcon />,
    [AppConstant.interStatusSended]: <Publish />,
    [AppConstant.interStatusDeclined]: <Clear />,
    [AppConstant.interStatusReceive]: <Check />,
    [AppConstant.interStatusQuoteReceive]: <HelpOutlineIcon />,
    [AppConstant.interStatusQuoteAccepted]: <Check />,
    [AppConstant.interStatusPaymentPending]: <LocalAtm />,
    [AppConstant.interStatusRepair]: <Settings />,
    [AppConstant.interStatusOnTheWay]: <GetApp />,
    [AppConstant.interStatusFinalized]: <Check />,
    [AppConstant.interStatusAlert]: <Warning />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const CustomConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: "15px",
    left: "-50%",
    right: "50%",
    position: "absolute",
  },
  active: {
    "& $line": {
      borderColor: theme.palette.success.main,
    },
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.success.main,
    },
  },
  line: {
    borderTopStyle: "solid",
    borderTopWidth: "2px",
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}))(StepConnector);

/**
 * Stepper from the status section
 *
 * @param   {Object}  intervention     Intervention sent by the api
 * @returns                            Component content
 */
const InterventionDetailStepper = ({ intervention }) => {
  const [steps, setSteps] = useState(null);
  const [activeStep, setActiveStep] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (intervention) {
      let stepsToSet = [];

      const keys = Object.keys(Test(t));
      let active = null;
      keys.forEach((key, idx) => {
        let newStep = {
          icon: null,
          label: null,
          style: null,
        };

        newStep.label = Test(t)[key]["label"];
        newStep.icon = Test(t)[key]["values"][0];

        // Avoid displaying declined icons when the status is not declined.
        // Also avoid displaying the next icons when the status is declined.
        if (
          active === AppConstant.interStatusDeclined ||
          key === "alert" ||
          (parseInt(intervention.status) !== AppConstant.interStatusDeclined &&
            key === "declined")
        ) {
          newStep.style = { display: "none" };
        }

        // [#125] Avoid displaying certain steps when the status is finalised after a proposal refusal for recycle.
        if (
          parseInt(intervention.status) === AppConstant.interStatusFinalized &&
          parseInt(intervention.proposalChoice) ===
            AppConstant.proposalChoiceNo &&
          ["quoteAccepted", "paymentPending", "repair", "onTheWay"].includes(
            key
          )
        ) {
          newStep.style = { display: "none" };
        }

        if (
          Test(t)[key]["values"].includes(parseInt(intervention.status))
        ) {
          active = idx;
          setActiveStep(idx);
        }

        stepsToSet.push(newStep);
      });

      setSteps(stepsToSet);
    }
  }, [intervention, t]);

  return steps ? (
    <Stepper
      activeStep={activeStep}
      orientation="horizontal"
      alternativeLabel={true}
      connector={<CustomConnector />}
      style={{ overflowX: "scroll" }}
    >
      {steps.map((item) => (
        <Step key={item.label} style={item.style}>
          <StepLabel StepIconComponent={CustomIcon} icon={item.icon}>
            {item.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  ) : (
    <Skeleton height={170} />
  );
};

export default InterventionDetailStepper;
