import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { CotroliaStepperActions } from "../stepper";

const useStyle = makeStyles((theme) => ({
  uploadContainer: {
    padding: `${theme.spacing(1.5)}px 0px 0px`,
  },
  mt: {
    marginTop: theme.spacing(2),
  },
}));

const NewInterventionStepSkeleton = () => {
  const classes = useStyle();
  return (
    <>
      <Typography
        variant="h4"
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <Skeleton variant="text" style={{ width: "50%" }} />
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <Skeleton variant="text" style={{ width: "40%" }} />
      </Typography>

      <Box className={classes.mt} style={{ width: "100%" }}>
        <Skeleton variant="rect" style={{ height: "70px" }} />
      </Box>

      <Box className={classes.mt} style={{ width: "100%" }}>
        <Skeleton variant="rect" style={{ height: "400px" }} />
      </Box>

      <CotroliaStepperActions>
        <Skeleton variant="rect" height={36} width={100} />
        <Skeleton variant="rect" height={36} width={100} />
      </CotroliaStepperActions>
    </>
  );
};

export default NewInterventionStepSkeleton;
