import React, { useState } from "react";

import {
  CircularProgress,
  InputAdornment,
  Box,
  makeStyles,
  Typography,
  Chip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import CotroliaTextField from "../core/input/CotroliaTextField";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import { componentList, categoryDefinition } from "./DocumentationConstant";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  suggestionContainer: {
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    width: "100%",
    maxHeight: "250px",
    borderRadius: theme.spacing(1) + 2,
    backgroundColor: theme.palette.grey[50],
    overflowY: "scroll",
    top: "56px",
    zIndex: 5,
  },
  entryContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5),
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      cursor: "pointer",
    },
  },
  entryContainerDisabled: {
    "&:hover": {
      cursor: "not-allowed",
    },
  },
  entryLabel: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
}));

const DocumentationSearch = () => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const classes = useStyle();
  const history = useHistory();

  const getSuggestions = (search) => {
    setLoading(true);
    setSuggestions(
      componentList.filter((component) =>
        component.name.toLowerCase().includes(search.toLowerCase())
      )
    );
    setLoading(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;

    setSearch(value);
    getSuggestions(value);
  };

  return (
    <>
      <CotroliaFormControl style={{ position: "relative" }}>
        <CotroliaTextField
          placeholder="Rechercher un composant"
          value={search}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{<SearchIcon />}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {loading ? (
                  <CircularProgress color="primary" size={24} />
                ) : (
                  <ClearIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSuggestions([]);
                      setSearch("");
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
        <Box className={classes.suggestionContainer}>
          {suggestions.map((item) => (
            <Box
              className={`${classes.entryContainer} ${
                !item.available ? classes.entryContainerDisabled : ""
              }`}
              onClick={() => item.available && history.push(`${item.url}`)}
              key={`suggestion-${item.name}`}
            >
              <Box className={classes.entryLabel}>
                <Typography variant="body1">{item.name}</Typography>
                <Typography variant="caption">{item.description}</Typography>
              </Box>

              {!item.available && (
                <Chip
                  label="⚠️ Non disponible"
                  variant="outlined"
                  style={{ marginRight: "1rem" }}
                />
              )}
              <Chip
                label={item.category}
                icon={categoryDefinition[item.category].icon}
                variant="outlined"
                color="primary"
              />
            </Box>
          ))}
        </Box>
      </CotroliaFormControl>
    </>
  );
};

export default DocumentationSearch;
