import { InputLabel, makeStyles } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
  },
}));

const CotroliaInputLabel = ({ additionalClass, ...props }) => {
  const classes = useStyle();
  return (
    <InputLabel className={`${classes.label} ${additionalClass}`} {...props}>
      {props.children}
    </InputLabel>
  );
};

export default CotroliaInputLabel;
