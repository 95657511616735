class Cipher {
  /**
   * Variable used for encryption
   */
  crypto = require("crypto-js");
  passphrase = process.env.REACT_APP_CIPHER_PASSWORD;

  /**
   * Method to encrypt the text using passphrase set in .env
   *
   * @param {string} text       Text to encrypt
   */
  encrypt = (text) => {
    return this.crypto.AES.encrypt(text, this.passphrase).toString();
  };

  /**
   * Method to decrypt the object using passphrase set in .env
   *
   * @param {Object} encrypted       Encrypted object to decrypt
   */
  decrypt = (encrypted) => {
    const bytes = this.crypto.AES.decrypt(encrypted, this.passphrase);
    return bytes.toString(this.crypto.enc.Utf8);
  };
}

export default new Cipher();
