import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import InboxIcon from "@material-ui/icons/Inbox";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

export const componentList = [
  {
    name: "CotroliaAddress",
    category: "Inputs",
    url: "/input/cotrolia-address",
    description: "Un champ qui permet de saisir une adresse",
    available: true,
  },
  {
    name: "CotroliaAutocomplete",
    category: "Inputs",
    url: "/input/cotrolia-autocomplete",
    description: "Un champ qui auto-complète une liste de valeurs",
    available: true,
  },
  {
    name: "CotroliaDatePicker",
    category: "Inputs",
    url: "/input/cotrolia-date-picker",
    description: "Un champ qui permet de choisir une date",
    available: true,
  },
  {
    name: "CotroliaFormControl",
    category: "Inputs",
    url: "/input/cotrolia-form-control",
    description: "Un container pour tous les champs",
    available: true,
  },
  {
    name: "CotroliaInputsLabel",
    category: "Inputs",
    url: "/input/cotrolia-input-label",
    description: "Un label pour un champs dans un container",
    available: true,
  },
  {
    name: "CotroliaSelect",
    category: "Inputs",
    url: "/input/cotrolia-select",
    description: "Un champ qui permet de choisir une valeur dans une liste",
    available: true,
  },
  {
    name: "CotroliaTextField",
    category: "Inputs",
    url: "/input/cotrolia-text-field",
    description: "Un champ de texte",
    available: false,
  },
  {
    name: "CotroliaInputSkeleton",
    category: "Skeletons",
    url: "/input/cotrolia-input-skeleton",
    description: "Un squelette de chargement de champ texte",
    available: false,
  },
  {
    name: "CotroliaTextareaSkeleton",
    category: "Skeletons",
    url: "/input/cotrolia-textarea-skeleton",
    description: "Un squelette de chargement de champ texte multiligne",
    available: false,
  },
  {
    name: "InterventionCardSkeleton",
    category: "Skeletons",
    url: "/input/intervention-card-skeleton",
    description: "Un squelette de chargement de carte d'intervention",
    available: false,
  },
  {
    name: "NewInterventionStepSkeleton",
    category: "Skeletons",
    url: "/input/new-intervention-step-skeleton",
    description:
      "Un squelette de chargement d'étape de création d'intervention",
    available: false,
  },
  {
    name: "NotificationCardSkeleton",
    category: "Skeletons",
    url: "/input/notification-card-skeleton",
    description: "Un squelette de chargement de carte de notification",
    available: false,
  },
  {
    name: "WizardSkeleton",
    category: "Skeletons",
    url: "/input/wizard-skeleton",
    description: "Un squelette de chargement de wizard",
    available: false,
  },
  {
    name: "Content",
    category: "Layout",
    url: "/layout/content",
    description: "Un container pour le contenu principal de toutes les pages",
    available: true,
  },
  {
    name: "ContentCard",
    category: "Layout",
    url: "/layout/content-card",
    description: "Un container pour appliquer un style de carte",
    available: true,
  },
];

export const categoryDefinition = {
  Inputs: {
    description: "Tous les champs de saisie",
    icon: <SpellcheckIcon />,
  },
  Skeletons: {
    description: "Tous les squelettes de chargement",
    icon: <HourglassEmptyIcon />,
  },
  Layout: {
    description: "Tous les composants de mise en page",
    icon: <InboxIcon />,
  },
  Other: {
    description: "Tous les autres composants",
    icon: <HelpOutlineIcon />,
  },
};
