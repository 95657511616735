import React from "react";
import { Box, makeStyles, Typography, useTheme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

// Style css
const useStyle = makeStyles((theme) => ({
  wizard: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: theme.spacing(0.5),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
  },

  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3.5),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

// Component WizardSkeleton
const WizardSkeleton = () => {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <Box className={classes.wizard}>
      <Box className={classes.header}>
        <Typography variant="overline" style={{ width: "20%" }}>
          <Skeleton variant="text" />
        </Typography>
        <Typography variant="overline" style={{ width: "24px" }}>
          <Skeleton variant="text" />
        </Typography>
      </Box>
      <Skeleton variant="rect" width="100%" height={42} />
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="100%" />
      <Box style={{ marginTop: theme.spacing(3.5) }}>
        <Skeleton variant="rect" width={100} height={30} />
      </Box>
    </Box>
  );
};

export default WizardSkeleton;
