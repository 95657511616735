import { Typography, Button, Box } from "@material-ui/core";
import AppConstant from "./appConstant";
import SaveIcon from "@material-ui/icons/Save";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CheckIcon from "@material-ui/icons/Check";
import CallMadeIcon from "@material-ui/icons/CallMade";
import GetAppIcon from "@material-ui/icons/GetApp";
import SettingsIcon from "@material-ui/icons/Settings";
import BuildIcon from "@material-ui/icons/Build";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import PublishIcon from "@material-ui/icons/Publish";
import { LocalAtm } from "@material-ui/icons";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import moment from "../services/moment";
import InterventionCard, {
  InterventionCardContentLine,
} from "../components/core/InterventionCard";
import InterventionCardSkeleton from "../components/core/skeleton/InterventionCardSkeleton";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { HourglassEmpty, Info, ReportProblem } from "@material-ui/icons";
import NoFilterResult from "../components/core/NoFilterResult";

const useStyle = makeStyles((theme) => ({
  cardMargin: {
    margin: theme.spacing(0, 2, 2, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 1, 2, 1),
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  btnDraft: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.info.main,
    },
  },
  btnValidated: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  btnOrange: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
  },
  btnBlue: {
    backgroundColor: "#2296f3",
    color: "white",
    "&:hover": {
      backgroundColor: "#2296f3",
    },
  },
  btnToModerate: {
    color: theme.palette.text.disabled,
  },
  btnRefused: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

const displayStatusButton = (t, classes, status, id, history) => {
  let button;

  switch (status) {
    case AppConstant.interStatusTransmited:
      button = (
        <>
          <Button
            startIcon={<CheckIcon />}
            className={`${classes.marginBottom} ${classes.btnToModerate}`}
            onClick={() => {
              history.push("/intervention/" + id);
            }}
          >
            {t("InterCardToModerate")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardToModerateDesc")}
          </Typography>
        </>
      );
      break;
    case AppConstant.interStatusDeclined:
      button = (
        <>
          <Button
            variant="contained"
            startIcon={<Info />}
            className={`${classes.marginBottom} ${classes.btnRefused}`}
            onClick={() => {
              history.push("/intervention/" + id);
            }}
          >
            {t("InterCardRefused")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardRefusedDesc")}
          </Typography>
        </>
      );
      break;
    case AppConstant.interStatusReceive:
      button = (
        <>
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            className={`${classes.marginBottom} ${classes.btnValidated}`}
            onClick={() => {
              history.push("/intervention/" + id);
            }}
          >
            {t("StatusReceive")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardReceiveDesc")}
          </Typography>
        </>
      );
      break;
    case AppConstant.interStatusToSend:
      button = (
        <>
          <Button
            variant="contained"
            startIcon={<MoveToInboxIcon />}
            className={`${classes.marginBottom} ${classes.btnOrange}`}
            onClick={() => {
              history.push("/intervention/" + id);
            }}
          >
            {t("StatusToSend")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardToSendDesc")}
          </Typography>
        </>
      );
      break;
    case AppConstant.interStatusSended:
      button = (
        <>
          <Button
            variant="contained"
            startIcon={<PublishIcon />}
            className={`${classes.marginBottom} ${classes.btnValidated}`}
            onClick={() => {
              history.push("/intervention/" + id);
            }}
          >
            {t("StatusSended")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardSendedDesc")}
          </Typography>
        </>
      );
      break;
    case AppConstant.interStatusQuoteReceive:
      button = (
        <>
          <Button
            variant="contained"
            startIcon={<HelpOutlineIcon />}
            className={`${classes.marginBottom} ${classes.btnOrange}`}
            onClick={() => {
              history.push("/intervention/" + id);
            }}
          >
            {t("StatusQuoteReceive")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardQuoteReceiveDesc")}
          </Typography>
        </>
      );
      break;
    case AppConstant.interStatusQuoteAccepted:
      button = (
        <>
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            className={`${classes.marginBottom} ${classes.btnOrange}`}
            onClick={() => {
              history.push("/intervention/" + id);
            }}
          >
            {t("StatusQuoteAccepted")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardQuoteAcceptedDesc")}
          </Typography>
        </>
      );
      break;
    case AppConstant.interStatusPaymentPending:
      button = (
        <>
          <Button
            variant="contained"
            startIcon={<LocalAtm />}
            className={`${classes.marginBottom} ${classes.btnOrange}`}
            onClick={() => {
              history.push("/intervention/" + id);
            }}
          >
            {t("StatusPaymentPending")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardPaymentPendingDesc")}
          </Typography>
        </>
      );
      break;
    case AppConstant.interStatusRepair:
      button = (
        <>
          <Button
            variant="contained"
            startIcon={<SettingsIcon />}
            className={`${classes.marginBottom} ${classes.btnBlue}`}
            onClick={() => {
              history.push("/intervention/" + id);
            }}
          >
            {t("StatusRepair")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardRepairDesc")}
          </Typography>
        </>
      );
      break;
    case AppConstant.interStatusOnTheWay:
      button = (
        <>
          <Button
            variant="contained"
            startIcon={<GetAppIcon />}
            className={`${classes.marginBottom} ${classes.btnBlue}`}
            onClick={() => {
              history.push("/intervention/" + id);
            }}
          >
            {t("StatusOnTheWay")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardOnTheWayDesc")}
          </Typography>
        </>
      );
      break;
    case AppConstant.interStatusFinalized:
      button = (
        <>
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            className={`${classes.marginBottom} ${classes.btnValidated}`}
            onClick={() => {
              history.push("/intervention/" + id);
            }}
          >
            {t("StatusFinalized")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardFinalizedDesc")}
          </Typography>
        </>
      );
      break;
    case AppConstant.interStatusRegistered:
    default:
      button = (
        <>
          <Button
            variant="contained"
            startIcon={<OpenInNewIcon />}
            className={`${classes.marginBottom} ${classes.btnDraft}`}
            onClick={() => {
              history.push("/intervention/new?id=" + id);
            }}
          >
            {t("InterCardDraft")}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center">
            {t("InterCardDraftDesc")}
          </Typography>
        </>
      );
      break;
  }

  return button;
};

const displayStatusIcon = (status) => {
  let icon;

  switch (status) {
    case AppConstant.interStatusTransmited:
      icon = <HourglassEmpty />;
      break;
    case AppConstant.interStatusDeclined:
      icon = <ReportProblem />;
      break;
    case AppConstant.interStatusRegistered:
      icon = <SaveIcon />;
      break;
    case AppConstant.interStatusReceive:
      icon = <CallMadeIcon />;
      break;
    case AppConstant.interStatusToSend:
      icon = <LocalShippingIcon />;
      break;
    case AppConstant.interStatusSended:
      icon = <LocalShippingIcon />;
      break;
    case AppConstant.interStatusQuoteReceive:
      icon = <AttachMoneyIcon />;
      break;
    case AppConstant.interStatusQuoteAccepted:
      icon = <AttachMoneyIcon />;
      break;
    case AppConstant.interStatusPaymentPending:
      icon = <AttachMoneyIcon />;
      break;
    case AppConstant.interStatusRepair:
      icon = <BuildIcon />;
      break;
    case AppConstant.interStatusOnTheWay:
      icon = <LocalShippingIcon />;
      break;
    case AppConstant.interStatusFinalized:
      icon = <BeenhereIcon />;
      break;
    default:
      icon = <CallMadeIcon />;
      break;
  }

  return icon;
};

const InterventionListCard = ({ item, history }) => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Box className={classes.cardMargin}>
      <InterventionCard
        interRef={item.ref}
        icon={displayStatusIcon(parseInt(item.status))}
        footer={t("LastEdit") + moment.unix(item.dateUpdate).fromNow()}
      >
        {item.customerRef && (
          <InterventionCardContentLine
            left={t("YourRef")}
            right={item.customerRef}
          />
        )}

        <InterventionCardContentLine
          left={t("Manager")}
          right={item.userCreat}
        />

        <Box
          display="flex"
          flexDirection="column"
          className={classes.marginTop}
        >
          {displayStatusButton(
            t,
            classes,
            parseInt(item.status),
            item.id,
            history
          )}
        </Box>
      </InterventionCard>
    </Box>
  );
};

const InterventionListSkeleton = ({ interventionNb }) => {
  const classes = useStyle();

  let skeletons = [];
  for (let i = 0; i < interventionNb; i++) {
    skeletons.push(
      <Box key={i} className={classes.cardMargin}>
        <InterventionCardSkeleton />
      </Box>
    );
  }

  return skeletons;
};

const displayCards = (isLoading, interventionNb, interventions, history) => {
  return (
    <>
      {interventions && isLoading === false ? (
        interventions.length > 0 ? (
          interventions.map((item, index) => (
            <InterventionListCard key={index} item={item} history={history} />
          ))
        ) : (
          <NoFilterResult />
        )
      ) : (
        <InterventionListSkeleton interventionNb={interventionNb} />
      )}
    </>
  );
};

export default displayCards;
