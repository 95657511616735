import React, { useEffect, useState } from "react";
import { Box, Collapse, makeStyles, useTheme } from "@material-ui/core";
import DividerWithIcon from "./DividerWithAction";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@material-ui/icons/Visibility";
import NotificationCard from "./NotificationCard";
import AppConstant from "../../utils/appConstant";
import { useMenu } from "../../context/menu-context";
import NotificationCardSkeleton from "./skeleton/NotificationCardSkeleton";
import { useAuth } from "../../context/auth-context";
import notificationService from "../../services/notification.service";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  cardContainer: {
    display: "flex",
    overflowX: "scroll",
    flexWrap: "nowrap",
    margin: theme.spacing(2, 0),
  },
  notifCardMargin: {
    margin: theme.spacing(0, 2, 2, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 1, 2, 1),
    },
  },
  containerPaddingSide: {
    padding: theme.spacing(0, 15, 0, 15),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2, 0, 2),
    },
  },
  containerPaddingSideOnlyLeft: {
    padding: theme.spacing(0, 0, 0, 15),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
}));

const NotificationDashboardList = () => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);
  const classes = useStyle();
  const [containerWidth, setContainerWidth] = useState(null);
  const menu = useMenu();
  const theme = useTheme();
  const [isLoading, setLoading] = useState(true);
  const [notificationNb, setNotificationNb] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const { user } = useAuth();

  const hideList = () => {
    setHidden((hidden) => !hidden);
  };

  useEffect(() => {
    let drawerWidth = 0;
    let paddingWidth = 0;
    if (menu.onSmartphone) {
      drawerWidth = menu.isOpen ? "" : "0"; // TODO : manage this calc
      paddingWidth = "0";
    } else {
      drawerWidth = menu.isOpen
        ? AppConstant.drawerWidth
        : AppConstant.drawerWidthClose;
      paddingWidth = theme.spacing(15);
    }
    setContainerWidth(`calc(100vw - ${drawerWidth}px - ${paddingWidth}px)`);
  }, [menu.isOpen, menu.onSmartphone, theme]);

  // Get all notifications for current user
  useEffect(() => {
    notificationService
      .getAllNotification()
      .then((res) => {
        setNotificationData(res.notifications);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [user.data.notificationNb]);

  // Set notification numbers for skeletons
  useEffect(() => {
    if (user.data && user.data.thirdparty) {
      setNotificationNb(user.data.notificationNb);
    }
  }, [user]);

  const displayCards = () => {
    let notifications = [];

    if (isLoading) {
      // Show skeletons while loading
      for (let i = 0; i < notificationNb; i++) {
        notifications.push(<NotificationCardSkeleton />);
      }
    } else {
      // Draw notifications card
      notificationData.forEach((el) => {
        notifications.push(<NotificationCard data={el} />);
      });
    }

    return (
      <>
        {notifications.map((item, index) => (
          <Box key={index} className={classes.notifCardMargin}>
            {item}
          </Box>
        ))}
      </>
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.containerPaddingSide}>
        <DividerWithIcon
          title={t("Informations")}
          icon={<VisibilityIcon />}
          action={hideList}
          actionName={hidden ? t("Show") : t("Hide")}
        />
      </Box>
      <Box className={classes.containerPaddingSideOnlyLeft}>
        <Collapse in={!hidden}>
          <Box
            className={classes.cardContainer}
            style={{ width: containerWidth }}
          >
            {displayCards()}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default NotificationDashboardList;
