import React, { useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import RegisterFormStep1 from "./RegisterFormStep1";
import RegisterFormStep2 from "./RegisterFormStep2";

const useStyle = makeStyles((theme) => ({
  container: {
    background: theme.palette.paper,
    justifyContent: "center",
    width: "30vw",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      alignSelf: "unset",
    },
  },
  title: {
    margin: `${theme.spacing(2)}px 0`,
  },
  desc: {
    color: theme.palette.grey[500],
    margin: `${theme.spacing(2)}px 0`,
  },
}));

const RegisterForm = () => {
  const classes = useStyle();
  const { t } = useTranslation();

  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [professional, setProfessional] = useState(false);
  const [thirdparty, setThirdparty] = useState("");
  const [vat, setVat] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const handleNext = () => {
    setStep(2);
  };

  const handlePrev = () => {
    setStep(1);
  };

  const displayStep = () => {
    const values = {
      name,
      firstname,
      email,
      professional,
      thirdparty,
      vat,
      password,
      rePassword,
    };

    const step1Handlers = {
      setName,
      setFirstname,
      setEmail,
      setProfessional,
      setThirdparty,
      setVat,
    };

    const step2Handlers = {
      setPassword,
      setRePassword,
    };

    return step === 1 ? (
      <RegisterFormStep1
        handleNext={handleNext}
        values={values}
        handlers={step1Handlers}
      />
    ) : (
      <RegisterFormStep2
        handlePrev={handlePrev}
        values={values}
        handlers={step2Handlers}
      />
    );
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {t("CreateAccount")}
      </Typography>
      <Typography variant="subtitle1" className={classes.desc}>
        {t("CreateAccountDesc")}
      </Typography>

      {displayStep()}
    </Box>
  );
};

export default RegisterForm;
