import React, { useState, useEffect } from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import authService from "../../services/auth.service";
import CotroliaPassword from "../core/input/CotroliaPassword";
import PasswordChecklist from "react-password-checklist";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import useCustomTheme from "../../utils/theme";

const useStyle = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    background: theme.palette.paper,
    width: "30vw",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      alignSelf: "unset",
    },
  },
  title: {
    margin: `${theme.spacing(2)}px 0`,
  },
  inputContainer: {
    width: "100%",
    margin: `${theme.spacing(1)}px 0`,
  },
  btn: {
    width: "100%",
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px 0`,
  },
  link: {
    width: "100%",
    textDecoration: "none",
  },
  passwordValidation: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    margin: `0 0 ${theme.spacing(1)}px 0 !important`,
    padding: `${theme.spacing(1)}px !important`,
    fontFamily: theme.typography.fontFamily,
  },
}));

const emptyError = {
  value: false,
  msg: "",
};

const ValidateAccountForm = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { theme } = useCustomTheme();
  const alert = useAlert();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState(emptyError);
  const [validPassword, setValidPassword] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const email = queryParams.get("email");

  useEffect(() => {
    if (password !== confirmPassword) {
      setEmailError({ value: true, msg: t("ErrorConfirmationPassword") });
    } else {
      setEmailError({ value: false, msg: "" });
    }
  }, [password, confirmPassword, t]);

  const handleResetPassword = async () => {
    if (password === confirmPassword) {
      try {
        await authService.postResetPassword(token, password);
        alert.show(t("YouCanLoginNow"), {
          title: t("SuccessPasswordReset"),
          close: () => {
            alert.remove(alert);
          },
          type: "success",
        });
        // Reditect to login
        history.push("/login");
      } catch (err) {
        console.error(err);
        alert.show(t("AskNewPasswordLink"), {
          title: t("ErrorPasswordReset"),
          close: () => {
            alert.remove(alert);
          },
          type: "error",
        });
      }
    }
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {t("ConfirmAccount")}
      </Typography>

      <Typography
        variant="body1"
        style={{
          marginBottom: theme.spacing(2),
          color: theme.palette.text.primary,
        }}
      >
        {t("ConfirmAccountEmailUsed") + email}
      </Typography>

      <CotroliaPassword
        className={classes.inputContainer}
        label={t("NewPassword")}
        value={password}
        handler={setPassword}
      />

      <CotroliaPassword
        className={classes.inputContainer}
        label={t("ConfirmPassword")}
        value={confirmPassword}
        handler={setConfirmPassword}
        error={emailError.value}
        helperText={emailError.value ? emailError.msg : null}
      />

      <PasswordChecklist
        rules={["minLength", "specialChar", "number", "capital", "match"]}
        minLength={8}
        value={password}
        valueAgain={confirmPassword}
        onChange={(isValid) => {
          setValidPassword(isValid);
        }}
        messages={{
          minLength: t("PasswordRuleMinLength"),
          specialChar: t("PasswordRuleSpecialChar"),
          number: t("PasswordRuleNumber"),
          capital: t("PasswordRuleCapital"),
          match: t("PasswordRuleMatch"),
        }}
        iconComponents={{
          ValidIcon: (
            <CheckIcon style={{ color: theme.palette.success.main }} />
          ),
          InvalidIcon: (
            <ClearIcon style={{ color: theme.palette.error.main }} />
          ),
        }}
        className={classes.passwordValidation}
      />

      <Button
        variant="contained"
        color="secondary"
        className={classes.btn}
        endIcon={<ArrowForwardIcon />}
        onClick={handleResetPassword}
        disabled={!validPassword}
      >
        {t("ValidateAccount")}
      </Button>

      <Link to="/login" className={classes.link}>
        <Button color="secondary" className={classes.btn}>
          {t("Cancel")}
        </Button>
      </Link>
    </Box>
  );
};

export default ValidateAccountForm;
