import React from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AttachmentIcon from "@material-ui/icons/Attachment";
import MemoryIcon from "@material-ui/icons/Memory";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import HistoryIcon from "@material-ui/icons/History";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Box, Drawer, useTheme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/auth-context";
import { useHistory } from "react-router-dom";
import CompanyLogo from "../core/CompanyLogo";
import CloseIcon from "@material-ui/icons/Close";
import { useMenu } from "../../context/menu-context";
import AppConstant from "../../utils/appConstant";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import { useConfiguration } from "../../context/configuration-context";

const useStyle = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
      "& > div": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  active: {
    "& > div": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&:hover": {
      cursor: "default",
    },
  },
  appbar: {
    height: theme.spacing(10),
    display: "flex",
    backgroundColor: theme.palette.background.default,
    justifyContent: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  entry: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.text.primary,
    borderRadius: theme.spacing(0.5),
    margin: `${theme.spacing(2)}px 0`,
    textDecoration: "none",
  },
  drawer: {
    width: AppConstant.drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: theme.zIndex.drawer,
  },
  drawerOpen: {
    width: AppConstant.drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.background.default,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: AppConstant.drawerWidthClose,
    backgroundColor: theme.palette.background.default,
  },
  drawerOpenSmartphone: {
    width: "100vw",
  },
  drawerCloseSmartphone: {
    width: 0,
  },
  divider: {
    borderColor: theme.palette.text.primary,
    border: "1px solid",
  },
}));

/**
 * Componant for each entry of the side menu
 */
const SideMenuEntry = ({
  icon,
  label,
  collapse,
  to,
  action,
  closeNavOnSmartphone,
}) => {
  const classes = useStyle();

  return to ? (
    <NavLink
      to={to}
      exact
      activeClassName={classes.active}
      className={classes.link}
      onClick={() => closeNavOnSmartphone()}
    >
      <Box p={1} className={classes.entry}>
        <Box display="flex" justifyContent="center">
          {icon}
        </Box>
        {!collapse && (
          <Box ml={2}>
            <Typography>{label}</Typography>
          </Box>
        )}
      </Box>
    </NavLink>
  ) : (
    <Box className={classes.link}>
      <Box p={1} className={classes.entry} onClick={action}>
        <Box display="flex" justifyContent="center">
          {icon}
        </Box>
        {!collapse && (
          <Box ml={2}>
            <Typography>{label}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

/**
 * Componant to display a divider on the side menu
 */
const SideMenuDivider = () => {
  const classes = useStyle();
  return <div className={classes.divider}></div>;
};

/**
 * Componant to display a section on the side menu
 */
const SideMenuSection = ({ title, collapse, children }) => {
  const theme = useTheme();
  return (
    <Box style={{ margin: `${theme.spacing(2)}px 0` }}>
      {!collapse && (
        <Typography style={{ color: theme.palette.text.secondary }}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

/**
 * Componant to display the side menu
 */
const SideMenu = ({ open }) => {
  const auth = useAuth();
  const history = useHistory();
  const { t } = useTranslation();
  const menu = useMenu();
  const classes = useStyle();
  const configuration = useConfiguration();
  const declination =
    configuration.declination.indexOf("dmenu") !== -1 ? "light" : "dark";

  const handleLogoutClick = () => {
    history.push("/");
    auth.logout();
  };

  return (
    <>
      <Drawer
        open={menu.isOpen}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: menu.isOpen,
          [classes.drawerClose]: !menu.isOpen,
          [classes.drawerOpenSmartphone]: menu.isOpen && menu.onSmartphone,
          [classes.drawerCloseSmartphone]: !menu.isOpen && menu.onSmartphone,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: menu.isOpen,
            [classes.drawerClose]: !menu.isOpen,
            [classes.drawerOpenSmartphone]: menu.isOpen && menu.onSmartphone,
            [classes.drawerCloseSmartphone]: !menu.isOpen && menu.onSmartphone,
          }),
        }}
      >
        <Box p={3}>
          <Box
            mb={5}
            mt={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <CompanyLogo
              variant={menu.isOpen ? "full" : "mini"}
              declination={declination}
            />
            {menu.onSmartphone && (
              <CloseIcon
                style={{ color: "white", cursor: "pointer" }}
                onClick={menu.closeNavOnSmartphone}
              />
            )}
          </Box>
          <SideMenuEntry
            icon={<DashboardIcon />}
            label={t("Dashboard")}
            collapse={!menu.isOpen}
            to="/"
            closeNavOnSmartphone={menu.closeNavOnSmartphone}
          />
          <SideMenuDivider />
          <SideMenuSection title={t("MyIntervention")} collapse={!menu.isOpen}>
            <SideMenuEntry
              icon={<MemoryIcon />}
              label={t("OnGoing")}
              collapse={!menu.isOpen}
              to="/intervention/ongoing"
              closeNavOnSmartphone={menu.closeNavOnSmartphone}
            />
            <SideMenuEntry
              icon={<HistoryIcon />}
              label={t("History")}
              collapse={!menu.isOpen}
              to="/intervention/history"
              closeNavOnSmartphone={menu.closeNavOnSmartphone}
            />
            <SideMenuEntry
              icon={<AddCircleOutlineIcon />}
              label={t("NewIntervention")}
              collapse={!menu.isOpen}
              to="/intervention/new"
              closeNavOnSmartphone={menu.closeNavOnSmartphone}
            />
          </SideMenuSection>
          <SideMenuDivider />
          <Box mt={12}></Box>
          <SideMenuSection title={t("Toolbox")} collapse={!menu.isOpen}>
            <SideMenuEntry
              icon={<AttachmentIcon />}
              label={t("PDFCatalog")}
              collapse={!menu.isOpen}
              to="/catalog"
              closeNavOnSmartphone={menu.closeNavOnSmartphone}
            />
          </SideMenuSection>
          <SideMenuDivider />
          <SideMenuSection title={t("MyAccount")} collapse={!menu.isOpen}>
            <SideMenuEntry
              icon={<PersonOutlineIcon />}
              label={t("MyAccount")}
              collapse={!menu.isOpen}
              to="/profile"
              closeNavOnSmartphone={menu.closeNavOnSmartphone}
            />
            <SideMenuEntry
              icon={<ExitToAppIcon />}
              label={t("Logout")}
              collapse={!menu.isOpen}
              action={handleLogoutClick}
            />
          </SideMenuSection>
          <SideMenuDivider />
          <SideMenuSection collapse={!menu.isOpen}>
            <SideMenuEntry
              icon={<MemoryIcon />}
              label={t("ContactUs")}
              collapse={!menu.isOpen}
              to="/contact"
              closeNavOnSmartphone={menu.closeNavOnSmartphone}
            />
          </SideMenuSection>
          <SideMenuDivider />
          {process.env.REACT_APP_ENVIRONMENT === "development" && (
            <>
              <SideMenuSection title={t("Developer")} collapse={!menu.isOpen}>
                <SideMenuEntry
                  icon={<DeveloperModeIcon />}
                  label={t("Components")}
                  collapse={!menu.isOpen}
                  to="/components"
                  closeNavOnSmartphone={menu.closeNavOnSmartphone}
                />
              </SideMenuSection>
              <SideMenuDivider />
            </>
          )}
          {!menu.onSmartphone && (
            <SideMenuEntry
              icon={
                menu.isOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />
              }
              label={t("ReduceMenu")}
              collapse={!menu.isOpen}
              action={menu.toggleOpen}
            />
          )}
          <div
            style={{ width: "100%", textAlign: "center", marginTop: "1rem" }}
          >
            <Typography variant="caption">
              Version {process.env.REACT_APP_VERSION}
            </Typography>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default SideMenu;
