import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Box,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles((theme) => ({
  root: {
    width: theme.spacing(48),
    padding: theme.spacing(3),
    height: `calc(100% - ${theme.spacing(6)}px)`, // Height without padding top and bottom
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      height: `calc(100% - ${theme.spacing(4)}px)`, // Height without padding top and bottom
      width: theme.spacing(35),
    },
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.grey[900],
    boxShadow: "0px 5px 15px 0px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    display: "flex",
    flexDirection: "column",
  },

  header: {
    color: theme.palette.text.primary,
    padding: 0,
  },
  headerAction: {
    margin: 0,
    alignSelf: "center",
  },

  content: {
    margin: theme.spacing(3, 0, 3, 0),
    padding: 0,
    flex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },

  footer: {
    justifyContent: "center",
    color: theme.palette.text.secondary,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginBtn: {
    marginTop: theme.spacing(0.5),
  },
}));

const InterventionCardSkeleton = ({ className }) => {
  const classes = useStyle();
  return (
    <Card className={`${classes.root} ${className}`}>
      <CardHeader
        classes={{ root: classes.header, action: classes.headerAction }}
        title={
          <Typography variant="h6">
            <Skeleton variant="text" />
          </Typography>
        }
      ></CardHeader>
      <CardContent classes={{ root: classes.content }}>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.marginTop}
        >
          <Typography variant="overline">
            <Skeleton variant="text" />
          </Typography>
          <Typography variant="overline">
            <Skeleton variant="text" />
          </Typography>
          <Skeleton variant="rect" height={36} className={classes.marginTop} />
          <Typography
            variant="body2"
            style={{ display: "flex", justifyContent: "center" }}
            className={classes.marginBtn}
          >
            <Skeleton variant="text" style={{ width: "60%" }} />
          </Typography>
        </Box>
      </CardContent>
      <CardActions classes={{ root: classes.footer }}>
        <Typography variant="caption" style={{ width: "80%" }}>
          <Skeleton variant="text" />
        </Typography>
      </CardActions>
    </Card>
  );
};

export default InterventionCardSkeleton;
