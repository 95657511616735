import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  makeStyles,
  Typography,
  useTheme,
  ThemeProvider,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import { useMenu } from "../../context/menu-context";
import { useConfiguration } from "../../context/configuration-context";

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing(8)}px ${theme.spacing(8)}px ${theme.spacing(
      4
    )}px`,
    "& svg": {
      width: "31.5px",
      height: "31.5px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(3)}px`,
    },
  },
  rootBottom: {
    height: "150px",
    width: "100%",
    position: "absolute",
    top: "99%",
    left: "0",
    zIndex: "2",
    content: "''",
  },
  titleRightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: theme.spacing(2),
  },
  titleLeftContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2),
  },
  menuIcon: {
    cursor: "pointer",
  },
}));

const HeaderWithTheme = ({
  variant,
  focus,
  title,
  titleIcon,
  titleDesc,
  children,
}) => {
  const classes = useStyle();
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();
  const [background, setBackground] = useState(null);
  const [color, setColor] = useState(null);
  const menu = useMenu();

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    switch (variant) {
      case "primary":
        setBackground(theme.palette.primary.main);
        setColor(theme.palette.primary.contrastText);
        break;
      case "secondary":
        setBackground(theme.palette.secondary.main);
        setColor(theme.palette.secondary.contrastText);
        break;
      case "warning":
        setBackground(theme.palette.warning.main);
        setColor(theme.palette.warning.contrastText);
        break;
      default:
        setBackground(theme.palette.background.default);
        setColor(theme.palette.text.primary);
        break;
    }
  }, [variant, theme]);

  return (
    <Box className={classes.root} style={{ backgroundColor: background }}>
      {focus ? (
        <>
          {menu.onSmartphone ? (
            <ArrowBackIcon style={{ color: color }} onClick={handleGoBack} />
          ) : (
            <Button
              variant="contained"
              onClick={handleGoBack}
              startIcon={<ArrowBackIcon />}
              style={{ backgroundColor: color, color: background }}
            >
              {t("GoBack")}
            </Button>
          )}
          <Box display="flex" alignItems="center" style={{ color: color }}>
            <Box className={classes.titleRightContainer}>
              <Typography variant="h4">{title}</Typography>
              <Typography variant="subtitle1">{titleDesc}</Typography>
            </Box>
            {titleIcon}
          </Box>
        </>
      ) : (
        <Box display="flex" alignItems="center" style={{ color: color }}>
          {menu.onSmartphone ? (
            <MenuIcon className={classes.menuIcon} onClick={menu.toggleOpen} />
          ) : (
            titleIcon
          )}
          <Box className={classes.titleLeftContainer}>
            <Typography variant={menu.onSmartphone ? "subtitle2" : "h4"}>
              {title}
            </Typography>
            <Typography variant="subtitle2">{titleDesc}</Typography>
          </Box>
        </Box>
      )}
      {children}
      <span
        className={classes.rootBottom}
        style={{ backgroundColor: background }}
      />
    </Box>
  );
};

const Header = (props) => {
  const configuration = useConfiguration();
  const theme = configuration.themes.menuTheme;

  return (
    <ThemeProvider theme={theme}>
      <HeaderWithTheme {...props}>{props.children}</HeaderWithTheme>
    </ThemeProvider>
  );
};

export default Header;
