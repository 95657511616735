import { useState } from "react";
import { createTheme } from "@material-ui/core";

const defaultTheme = createTheme();

/*const repturnTheme = createTheme({
  palette: {
    text: {
      primary: "rgba(17, 38, 13, 0.87)",
      secondary: "rgba(31, 50, 27, 0.54)",
      disabled: "rgba(166, 128, 89, 0.5)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    primary: {
      main: "#348F24",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    secondary: {
      main: "#EAA135",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    grey: {
      50: "#F1F3F1",
      100: "rgba(228, 232, 227, 0.87)",
      200: "#C9D1C7",
      300: "#ADBAAB",
      400: "#92A38F",
      500: "#778C73",
      600: "#5F705C",
      700: "#475445",
      800: "#2C3D29",
      900: "#12240F",
    },
    error: {
      main: "#FF470D",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    success: {
      main: "#4CAF50",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: "#EAD4B4",
      contrastText: "#1B1B1B",
    },
    warning: {
      main: "#EAA135",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    background: {
      paper: "#FFFFFF",
      default: "#F2F3F2",
    },
  },
  typography: {
    fontFamily: ['"Roboto Mono"', "Roboto"].join(","),
    h1: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "96px",
      lineHeight: "112px",
    },
    h2: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "60px",
      lineHeight: "72px",
    },
    h3: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "48px",
      lineHeight: "56px",
    },
    h4: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "34px",
      lineHeight: "42px",
      letterSpacing: "-0.05em",
    },
    h5: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "-0.03em",
    },
    h6: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "-0.04em",
    },
    button: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "24px",
      textTransform: "uppercase",
      fontFeatureSettings: "'cpsp' on",
    },
    overline: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    caption: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "20px",
    },
    subtitle1: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "28px",
    },
    subtitle2: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "22px",
    },
    body1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
    },
    body2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
});*/

/*const repturnDarkTheme = createTheme({
  palette: {
    text: {
      primary: "rgba(241, 243, 241, 0.87)",
      secondary: "rgba(241, 243, 241, 0.54)",
      disabled: "rgba(184, 153, 122, 0.5)",
      hint: "rgba(255, 255, 255, 0.3)",
    },
    primary: {
      main: "#348F24",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    secondary: {
      main: "#EAA135",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    grey: {
      50: "#12240F",
      100: "#2C3D29",
      200: "#475445",
      300: "#5F705C",
      400: "#778C73",
      500: "#92A38F",
      600: "#ADBAAB",
      700: "#C9D1C7",
      800: "rgba(228, 232, 227, 0.87)",
      900: "#F1F3F1",
    },
    error: {
      main: "#FF470D",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    success: {
      main: "#4CAF50",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: "#EAD4B4",
      contrastText: "#1B1B1B",
    },
    warning: {
      main: "#EAA135",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    background: {
      paper: "#2C3D29",
      default: "#12240F",
    },
  },
  typography: {
    fontFamily: ['"Roboto Mono"', "Roboto"].join(","),
    h1: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "96px",
      lineHeight: "112px",
    },
    h2: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "60px",
      lineHeight: "72px",
    },
    h3: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "48px",
      lineHeight: "56px",
    },
    h4: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "34px",
      lineHeight: "42px",
      letterSpacing: "-0.05em",
    },
    h5: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "-0.03em",
    },
    h6: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "-0.04em",
    },
    button: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "24px",
      textTransform: "uppercase",
      fontFeatureSettings: "'cpsp' on",
    },
    overline: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "24px",
      textTransform: "uppercase",
    },
    caption: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "20px",
    },
    subtitle1: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "28px",
    },
    subtitle2: {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "22px",
    },
    body1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
    },
    body2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
});*/

const useCustomTheme = () => {
  const [theme, setTheme] = useState(defaultTheme);
  const [menuTheme, setMenuTheme] = useState(defaultTheme);

  return {
    theme,
    menuTheme,
    setTheme,
    setMenuTheme
  };
};

export default useCustomTheme;
