import React, { useState } from "react";
import {
  Box,
  makeStyles,
  Typography,
  useTheme,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// Style css
const useStyle = makeStyles((theme) => ({
  wizard: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(0.5),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
  },

  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3.5),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

// Component Wizard
const Wizard = ({ title, children }) => {
  const classes = useStyle();
  const theme = useTheme();
  const [state, setstate] = useState("block");

  const closeWizard = () => {
    setstate("none");
  };

  return (
    <Box className={classes.wizard} style={{ display: state }}>
      <Box className={classes.header}>
        <Typography variant="overline">{title}</Typography>
        <Button style={{ padding: "0", minWidth: "auto" }}>
          <CloseIcon
            onClick={closeWizard}
            style={{ color: theme.palette.primary.contrastText }}
          ></CloseIcon>
        </Button>
      </Box>
      {children}
    </Box>
  );
};

export default Wizard;
